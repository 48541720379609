
<ng-template #content let-modal>
    <h3>QR Scanner</h3>
    <ngx-scanner-qrcode #action="scanner" style="width: 60%;margin-left: 20%;height: 100%;" (event)="on_scan_result()" id="sc1"></ngx-scanner-qrcode>
    <button (click)="scan(action)">Scan</button>
    <button (click)="switch_cam()">S</button>
</ng-template>
<!-- {{value}}
<button (click)="open_scanner(content, 'value')">SCAN</button> -->
<div class="main" style="width: 100%;">
    <h2 style="text-align: center;">Configure Test</h2>
    <div class="row" *ngFor="let key of objectKeys(testConfig.equipmentConfig)" style="width: 100%;background-color: rgba(182, 174, 224, 0.39);width: 96%;margin-left: 2%;margin-bottom: 2px;">
        <div class="col-6" style="text-align: left;">
            <b>Machine:</b> {{key}}
        </div>
        <div class="col-6" style="text-align: right;">
            <button (click)="removeMachine(key)">Remove Machine</button>
        </div>
        <div *ngFor="let channel of testConfig.equipmentConfig[key]; let i = index" class="col-md-6 col-xl-3 col-sm-6 channel" style="text-align: center;">
            <div *ngIf="channel!=null">
                <b>{{channel}}</b>
                <span (click)="removeCell(key, i)" class="span-close">X</span>
            </div>
            <div *ngIf="channel==null">
                <input type="text" [(ngModel)]="tempConfig.equipmentConfig[key][i]" placeholder="Nunam Id channel-{{i+1}}"/>
                <button (click)="addCell(key, i)">Add Cell</button>
                <button (click)="open_scanner(content, 'value', key, i)">QR</button>
            </div>
        </div>
        
        
    </div>
    
    <hr/>

    <div class="row">
        <div class="col-12" style="text-align: center;">
            <select name="selectedMachineId" [(ngModel)]="selectedMachineId">
                <option *ngFor="let equipment of testEquipments" value="{{equipment.newareId}}">{{equipment.id}}</option>
            </select>
            <button (click)="addMachine()">
                Add Machine
            </button>
        </div>
    </div>

    <hr/>
    
    <div class="row" style="text-align: center;">
        <div class="col-12">
            <label>Batch Id</label>
            <input [(ngModel)]="testConfig.batchId" placeholder="Batch Id"/>
        </div>
        <div class="col-6">
            <label>Protocol</label>
            <select name="selectedProtocolId" [(ngModel)]="testConfig.protocol">
                <option *ngFor="let protocol of protocols" value="{{protocol.id}}">{{protocol.name}}-{{protocol.id}}</option>
            </select>
        </div>
        <div class="col-6">
            <label>Charge C Rate</label>
            <input [(ngModel)]="testConfig.chargeCRate" placeholder="Charge C Rate"/>
        </div>
        <div class="col-6">
            <label>Discharge C Rate</label>
            <input [(ngModel)]="testConfig.dischargeCRate" placeholder="Discharge C Rate"/>
        </div>
        <div class="col-6">
            <label>Number Of cycles</label>
            <input [(ngModel)]="testConfig.numberOfCycles" placeholder="Number Of cycles"/>
        </div>
    </div>

    <hr/>

    <div class="row" style="text-align: center;">
        <div class="col-12">
            <button (click)="saveTest()">Save Tests</button>
        </div>
    </div>
</div>
