import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TestDetail } from '../test-detail';

@Component({
  selector: 'app-test-detail-management',
  templateUrl: './test-detail-management.component.html',
  styleUrls: ['./test-detail-management.component.css']
})
export class TestDetailManagementComponent implements OnInit {

  private batchId!:Number
  public testDetails!:TestDetail[]
  constructor(private route:ActivatedRoute) {
    this.route.params.subscribe( params => {
      this.batchId = params['id']
      const data = JSON.parse(window.localStorage.getItem(this.batchId+'')+'')
      console.log(Object.keys(window.localStorage))
      console.log(data)
      this.testDetails = data['testDetails']
    });
   }

  ngOnInit(): void {
  }

}


