// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  server_host: "",
  authUrl: ""
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


// IMPORTANT NOTES:
//  - Congratulations! Your certificate and chain have been saved at:
//    /etc/letsencrypt/live/iot.nunam.com/fullchain.pem
//    Your key file has been saved at:
//    /etc/letsencrypt/live/iot.nunam.com/privkey.pem
//    Your cert will expire on 2021-07-01. To obtain a new or tweaked
//    version of this certificate in the future, simply run certbot
//    again. To non-interactively renew *all* of your certificates, run
//    "certbot renew"
//  - If you like Certbot, please consider supporting our work by:

//    Donating to ISRG / Let's Encrypt:   https://letsencrypt.org/donate
//    Donating to EFF:                    https://eff.org/donate-le