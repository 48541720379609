import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { DbServiceService } from 'src/app/shared/services/dbservice.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-neware-tests',
  templateUrl: './neware-tests.component.html',
  styleUrls: ['./neware-tests.component.css']
})
export class NewareTestsComponent implements OnInit {

  constructor(private http:HttpClient, private cookieService:CookieService, private route:ActivatedRoute) {

  }

  public message:String = ""
  public tests:any[] = []
  public filter:any = {}
  public itemsPerPage:Number = 50
  public totalTests!:Number
  public totalNumberOfPages!:Number
  public pagesPerDiaplay:Number = 10
  public s!:Number
  
  public machines:any[] = []
  public channels:Number[] = []

  public machineId!:Number
  public channel!:Number
  public startTime!:Number
  public endTime!:Number
  

  public statusMap:any = {
    0: "Running",
    1: "Data Uploading",
    2: "Storing Data",
    3: "Ready to Download",
    4: "Ready to Download"

  }
  
  

  ngOnInit(): void {
    new DbServiceService(this.http, environment.server_host+"/api/test-equipments", this.cookieService).getAll().subscribe(data=>{
      this.machines = data
      console.log(this.machines)
    })
    this.filter = {}
    this.loadFilter()
    this.load(1)
    this.s = 1    
  } 


  public load(pageNumber:Number){
    new DbServiceService<any, Number>(this.http, environment.server_host+"/api/neware-tests/filter?offset="+((+pageNumber-1)*+this.itemsPerPage)+"&limit="+this.itemsPerPage, this.cookieService).add(this.filter).subscribe(data=>{
      this.tests = data
    })
  }

  public selectMachine(){
    this.channels = []
    if(this.machineId==240041){
      this.channels = [1, 2, 3, 4, 5, 6, 7, 8]
    }else{
      this.channels = [1, 2, 3, 4]
    }
  }

  public applyFilter(){
    this.filter = {}
    if(this.channel!=-1){
      this.filter['channelId'] = +this.channel
    }
    if(this.machineId!=-1){
      this.filter['testEquipmentId'] = +this.machineId
    }
    if(this.startTime!=null){
      this.filter['startTime'] = +this.startTime
    }
    if(this.endTime!=null){
      this.filter['endTime'] = +this.endTime
    }
    this.loadFilter()
    this.load(1)

  }

  public loadFilter(){  
    new DbServiceService<Number, Number>(this.http, environment.server_host+"/api/neware-tests/filter/count", this.cookieService).add(this.filter).subscribe(data=>{
      this.totalTests = +data
      var totalPages = +this.totalTests/+this.itemsPerPage
      this.totalNumberOfPages = Number(totalPages.toFixed(0))
      if(+totalPages%+this.itemsPerPage!=0){
        this.totalNumberOfPages  = +this.totalNumberOfPages + 1
      }
    })
  }

  public nextPages(){
    var c = +this.s + +this.pagesPerDiaplay
    if (c > this.totalNumberOfPages)
      return
    this.s = c
  }

  public prevPages(){
    if(this.s==1)
      return
    this.s = +this.s - +this.pagesPerDiaplay
  }

  download(id:any){

  }

  getDateString(ts: number){
    return new Date(ts).toLocaleString("en-IN")
  }

}
