import { Component, OnInit } from '@angular/core';
import { DbServiceService } from '../shared/services/dbservice.service';
import { EWaste } from '../models/ewaste';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Pack } from '../models/pack';
import { PhysicalCondition } from '../models/physical-condition';


@Component({
  selector: 'app-dismantling',
  templateUrl: './dismantling.component.html',
  styleUrls: ['./dismantling.component.css']
})
export class DismantlingComponent implements OnInit {

  private eWasteService!:DbServiceService<EWaste,Number>;
  private packService!:DbServiceService<Pack, Number>;
  private conditionService!:DbServiceService<PhysicalCondition, Number>;

  public eWasteSources!:EWaste[]
  public batteryTypes!:String[]
  public packConditions!:PhysicalCondition[]
  public nominalVoltages !: Number[]
  public laptopBrand!:String[]
  public page!:Number
  public pack!:Pack

  constructor(private http:HttpClient,private cookieService:CookieService) { 
    this.eWasteSources = []
    this.eWasteService = new DbServiceService(http, environment.server_host+"/api/e-wastes", cookieService);
    this.packService = new DbServiceService(http,environment.server_host+"/api/packs", cookieService)
    this.conditionService = new DbServiceService(http,environment.server_host+"/api/physical-conditions", cookieService)
    
  }

  

  ngOnInit(): void {
    this.packConditions = []
    this.pack = new Pack()
    this.eWasteService.getAll().subscribe(data=>{
      this.eWasteSources = data
    })
    this.conditionService.getAll().subscribe(data=>{
      this.packConditions = data
    })
    this.batteryTypes = ["Solar", "Laptop", "2-Wheeler EV", "3-Wheeler EV", "Large EV", "EV", "Telecom"]
    this.nominalVoltages = [10.8, 11.1, 11.3, 10.95, 11.2, 14.4, 14.6, 14.8, 15]
    this.laptopBrand = ["BFN", "CHAM", "DLF", "DYSON", "FP", "FST", "GP Batteries", "Great Power", "Highstar", "Hitachi Maxell", "HYB", 
                        "KMASHI", "Lei","Lishen","Moli Energy", "PKCELL", "Re-Volt","SINC","Tenergy","Toshiba","Uni-Sun","Valence",
                        "YikLik", "LG", "SAMSUNG", "SANYO", "SONY", "PANASONIC", "UNKNOWN", "CHINESE", "A123System", "ATL", "BAL"
                        ]
    this.page = 1
  }

  setBatteryType(type:String){
    this.pack.source = type
    if(type != 'Laptop'){
      // this.page = this.page.valueOf()+1
    }
  }

  setBatteryBrand(brand:String){
    this.pack.laptopBrand = brand
    // this.page = this.page.valueOf()+1
  }


  setPackCondition(condition:PhysicalCondition){
    var index = this.pack.physicalConditionsIds.indexOf(condition.id)
    if(index>-1){
      this.pack.physicalConditionsIds.splice(index, 1)
    }else{
      this.pack.physicalConditionsIds.push(condition.id)
    }
    // if(this.page<=3){
    //   this.page = this.page.valueOf()+1
    // }
  }

  setNominalVoltage(voltage:Number){
    console.log(voltage)
    this.pack.nominalVoltage = voltage;
  }

  setEwasteSource(source:Number){
    this.pack.eWasteId = source
    // this.page = 2
  }

  getEwasteName(id:Number):string{
    if(this.eWasteSources.length>0){
      for(var v=0;v<this.eWasteSources.length;v++){
        if(this.eWasteSources[v].id==id){
          return this.eWasteSources[v].name.valueOf()
        }
      }
      return ""
    }else{
      return ""
    }
  }

  add(){
    if(this.pack.nominalVoltage==null){
      alert("please enter nominal voltage")
      return 
    }
    if(this.pack.id==null){
      alert("please enter pack Id")
      return 
    }
    if(confirm(this.getPackString())){
      this.packService.add(this.pack).subscribe(data=>{
        var id = data.id
        alert("Pack Added Successfully with Id:"+ id)
        this.pack = new Pack()
        this.page=1
      }, error=>{
        alert("something went wrong")
      })
      
    }
  }

  private getPackString():string{
    var packStr = "Are you sure to Add Pack \n"
    packStr = packStr + "Ewaste Source: "+ this.getEwasteName(this.pack.eWasteId) +"\n"
    packStr = packStr + "Battery Type: "+ this.pack.source +"\n"
    if(this.pack.source == 'Laptop'){
      packStr = packStr + "Laptop Brand: "+ this.pack.laptopBrand +"\n"
    }
    packStr = packStr + "Pack Condition: "+ this.pack.packCondition +"\n"
    packStr = packStr + "Nominal Voltage: "+ this.pack.nominalVoltage +"\n"
    packStr = packStr + "Year Of Manufacturer: "+ this.pack.manufacturingYear +"\n"
    packStr = packStr + "Capacity: "+ this.pack.capacity +"\n"
    packStr = packStr + "Output: "+ this.pack.outputWh +"\n"
    packStr = packStr + "Comments: "+ this.pack.comments +"\n"
    packStr = packStr + "BMS Data: "+ this.pack.bmsData +"\n"

    return packStr
  }


}
