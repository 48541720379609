import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Pack } from 'src/app/models/pack';
import { DbServiceService } from 'src/app/shared/services/dbservice.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { EWaste } from 'src/app/models/ewaste';
import { PhysicalCondition } from 'src/app/models/physical-condition';
import { environment } from 'src/environments/environment';
import { BMS } from 'src/app/models/bms';
import { Strings } from 'src/app/models/strings';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Module } from 'src/app/models/module';

@Component({
  selector: 'app-pack-details',
  templateUrl: './pack-details.component.html',
  styleUrls: ['./pack-details.component.css']
})
export class PackDetailsComponent implements OnInit {



  private eWasteService!:DbServiceService<EWaste,Number>;
  private packService!:DbServiceService<Pack, Number>;
  private conditionService!:DbServiceService<PhysicalCondition, Number>;
  private bmsService!:DbServiceService<BMS, Number>;
  private stringService!:DbServiceService<Strings,Number>
  private moduleService!:DbServiceService<Module,Number>

  public eWasteSources!:EWaste[]
  public batteryTypes!:String[]
  public packConditions!:PhysicalCondition[]
  public nominalVoltages !: Number[]
  public laptopBrand!:String[]
  public page!:Number
  public addCells!:Boolean
  public pack!:Pack
  public packId!:Number
  public bmsData!:String
  public modules!:Module[]
  public isModuled!:Boolean
  public addString!:Boolean
  public strings!:Strings[]
  
  

  
  

  constructor(private route:ActivatedRoute, private http:HttpClient,private cookieService:CookieService, private modalService:NgbModal) { 
    this.route.params.subscribe( params => this.packId = params['id']);
    this.eWasteSources = []
    this.eWasteService = new DbServiceService(http, environment.server_host+"/api/e-wastes", cookieService);
    this.packService = new DbServiceService(http,environment.server_host+"/api/packs", cookieService)
    this.conditionService = new DbServiceService(http,environment.server_host+"/api/physical-conditions", cookieService)
    this.bmsService = new DbServiceService(http,environment.server_host+"/api/bms", cookieService)
    
   
  }

  

  ngOnInit(): void {
    this.isModuled = false
    this.stringService = new DbServiceService(this.http, environment.server_host+"/api/pack/strings/"+this.packId, this.cookieService)
    this.moduleService = new DbServiceService(this.http, environment.server_host+"/api/pack/modules/"+this.packId, this.cookieService)
    this.pack = new Pack()
    this.batteryTypes = ["Solar", "Laptop", "2-Wheeler EV", "3-Wheeler EV", "Large EV", "EV"]
    this.nominalVoltages = [10.8, 11.1, 11.3, 10.95, 11.2, 14.4, 14.6, 14.8, 15]
    this.laptopBrand = ["BFN", "CHAM", "DLF", "DYSON", "FP", "FST", "GP Batteries", "Great Power", "Highstar", "Hitachi Maxell", "HYB", 
                        "KMASHI", "Lei","Lishen","Moli Energy", "PKCELL", "Re-Volt","SINC","Tenergy","Toshiba","Uni-Sun","Valence",
                        "YikLik", "LG", "SAMSUNG", "SANYO", "SONY", "PANASONIC", "UNKNOWN", "CHINESE", "A123System", "ATL", "BAL"
                        ]
    this.packConditions = [] 
    this.syncData()
    
  }

  syncData(){
    this.eWasteService.getAll().subscribe(data=>{
      this.eWasteSources = data
      this.conditionService.getAll().subscribe(data=>{
        this.packConditions = data
        this.packService.get(this.packId).subscribe(data=>{
          this.pack = data
          this.bmsData = this.getBMSDataString(this.pack.bms)
          this.stringService.getAll().subscribe(data=>{
            this.strings = data
            if(this.strings.length > 0){
              this.isModuled = false
            }
          })
          this.moduleService.getAll().subscribe(data=>{
            this.modules = data
            if(this.modules.length > 0){
              this.isModuled = true
            }
          })
        })
      })
    })
  }

  setBatteryType(type:String){
    this.pack.source = type
    if(type != 'Laptop'){
    }
  }

  setBatteryBrand(brand:String){
    this.pack.laptopBrand = brand
  }


  setPackCondition(condition:PhysicalCondition){
    var index = this.pack.physicalConditionsIds.indexOf(condition.id)
    if(index>-1){
      this.pack.physicalConditionsIds.splice(index, 1)
    }else{
      this.pack.physicalConditionsIds.push(condition.id)
    }
  }

  setNominalVoltage(voltage:Number){
    console.log(voltage)
    this.pack.nominalVoltage = voltage;
  }

  setEwasteSource(source:Number){
    this.pack.eWasteId = source
  }

  getEwasteName(id:Number):string{
    if(this.eWasteSources.length>0){
      for(var v=0;v<this.eWasteSources.length;v++){
        if(this.eWasteSources[v].id==id){
          return this.eWasteSources[v].name.valueOf()
        }
      }
      return ""
    }else{
      return ""
    }
  }

  update(){
    if(confirm(this.getPackString())){
      if(this.getBMSDataString(this.pack.bms)!=this.bmsData){
        const dt = {data:this.bmsData}
        const bms = {id:this.pack.bms.id, time:this.pack.bms.time, data:dt}
        this.bmsService.update(bms).subscribe(data=>{
          this.updatePck()
        })
      }else{
        this.updatePck()
      }
    }
  }

  updatePck(){
    this.packService.update(this.pack).subscribe(data=>{
      var id = data.id
      alert("Pack Updated Successfully with Id:"+ id)
      this.syncData()
    }, error=>{
      alert("something went wrong")
    })
  }

  private getPackString():string{
    var packStr = "Are you sure to Add Pack \n"
    packStr = packStr + "Ewaste Source: "+ this.getEwasteName(this.pack.eWasteId) +"\n"
    packStr = packStr + "Battery Type: "+ this.pack.source +"\n"
    if(this.pack.source == 'Laptop'){
      packStr = packStr + "Laptop Brand: "+ this.pack.laptopBrand +"\n"
    }
    packStr = packStr + "Pack Condition: "+ this.pack.packCondition +"\n"
    packStr = packStr + "Nominal Voltage: "+ this.pack.nominalVoltage +"\n"
    packStr = packStr + "Year Of Manufacturer: "+ this.pack.manufacturingYear +"\n"
    packStr = packStr + "Capacity: "+ this.pack.capacity +"\n"
    packStr = packStr + "Output: "+ this.pack.outputWh +"\n"
    packStr = packStr + "Comments: "+ this.pack.comments +"\n"
    packStr = packStr + "BMS Data: "+ this.bmsData +"\n"

    return packStr
  }

  getBMSDataString(map:BMS){
    
    if(map==null || map.data==null)
      return ""
    var str =  JSON.stringify(map.data);
    str = str.replace("{\"data\":\"", "")
    str = str.substring(0, str.length-2)
    return str
  }
  closeResult!:string
  open(content:any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  closeModal(){
   
    this.modalService.dismissAll()
    this.syncData()
  }



}
