<div class="row">
    <div class="col-6" style="text-align: center;">
        <mat-form-field appearance="outline">
            <mat-label>String Voltage</mat-label>
            <input matInput placeholder="voltage" [(ngModel)]="strings.voltage">
            <mat-hint></mat-hint>
        </mat-form-field>
    </div>
    <div class="col-6" style="text-align: center;">
        <mat-form-field appearance="outline">
            <mat-label>ACIR</mat-label>
            <input matInput placeholder="acir" [(ngModel)]="strings.acir">
            <mat-hint></mat-hint>
        </mat-form-field>
    </div>
    <div class="col-12"><h4 style="text-align: center;">Pack Condition</h4></div>
    <div class="col-4 " *ngFor="let condition of packConditions" >
        <div [ngClass]="{'active-card': strings.physicalConditionsIds.indexOf(condition.id) > -1, 'c-card': strings.physicalConditionsIds.indexOf(condition.id) == -1}" (click)="setPackCondition(condition)" >{{condition.name}}</div>
    </div>
    <div class="col-12"><button (click)="save()"><span *ngIf="stringId==-1">ADD</span><span *ngIf="stringId!=-1">UPDATE</span></button></div>
</div>
