import { Injectable } from '@angular/core';
import { DbServiceService } from '../shared/services/dbservice.service';
import { Pack } from '../models/pack';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PackService extends DbServiceService<Pack,Number>{

  constructor(http:HttpClient, link:String, cookieService:CookieService){
    super(http,link,cookieService)

  }

  getWithFilterAndLimit(key:String, limit:Number):Observable<Pack[]>{
    const headerDict = {
      'Accept': 'application/json',
      'Authorization': "Bearer "+ this.cookieService.get("access-token") 
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    return this.http.get<Pack[]>(this.link.valueOf()+"?orderBy="+key+"&limit="+limit,requestOptions)
  }


}
