import { Component, OnInit, Input } from '@angular/core';
import { Module } from 'src/app/models/module';
import { PhysicalCondition } from 'src/app/models/physical-condition';
import { DbServiceService } from '../../services/dbservice.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-module',
  templateUrl: './add-module.component.html',
  styleUrls: ['./add-module.component.css']
})
export class AddModuleComponent implements OnInit {

  
  public module!:Module
  public packConditions!:PhysicalCondition[]
  
  @Input() public moduleId!:Number
  @Input() public parentId!:Number

  private conditionsService!:DbServiceService<PhysicalCondition,Number>
  private moduleService!:DbServiceService<Module,Number>
  
  constructor(private http:HttpClient, private cookieService:CookieService,private route:ActivatedRoute) {
    this.packConditions = []
    this.conditionsService = new DbServiceService(this.http, environment.server_host+"/api/physical-conditions",this.cookieService)
    this.route.params.subscribe( params => this.moduleId = params['id']);
   }

  ngOnInit(): void {
    this.module = new Module()
    this.syncData()
  }

  syncData(){
    this.conditionsService.getAll().subscribe(data=>{
      this.packConditions = data
      if(this.moduleId==-1){
        this.module = new Module()
      }else{
        console.log(this.moduleId)
        this.moduleService = new DbServiceService(this.http, environment.server_host+"/api/modules",this.cookieService)
        this.moduleService.get(this.moduleId).subscribe(data=>{
          this.module = data
        })
      }
    })
  }

  setPackCondition(cond:PhysicalCondition){
    var index = this.module.physicalConditionsIds.indexOf(cond.id)
    if(index > -1){
      this.module.physicalConditionsIds.splice(index,1)
    }else{
      this.module.physicalConditionsIds.push(cond.id)
    }
  }

  save(){
    if(this.moduleId==-1){
      this.module.packId = this.parentId
      this.moduleService = new DbServiceService(this.http, environment.server_host+"/api/modules",this.cookieService)
      this.moduleService.add(this.module).subscribe(data=>{
       alert("Module Added Successfully")
      }, error=>{
        alert("something went wrong")
      })
    }else{
      this.moduleService.update(this.module).subscribe(data=>{
        alert("Module Updated Successfully")
      }, error=>{
        alert("something went wrong")
      })
    }
  }

}
