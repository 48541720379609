import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DbServiceService } from 'src/app/shared/services/dbservice.service';
import { environment } from 'src/environments/environment';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';

@Component({
  selector: 'app-conduct-test',
  templateUrl: './conduct-test.component.html',
  styleUrls: ['./conduct-test.component.css']
})
export class ConductTestComponent implements OnInit {

  constructor(private modalService:NgbModal, private http:HttpClient, private cookieService:CookieService) { }

  @ViewChild("scanner") scnnerComponent!:HTMLElement

  objectKeys = Object.keys;

  public testEquipments:any
  public protocols!:any[]

  public selectedMachineId!:Number

  public manufacturers:any[] = []
  public models:any[] = []


  public testConfig:any

  public tempConfig:any

  private cellFamilyRefs:any

  getMachineIdfromNewareId(newareId:Number){
      this.testEquipments.forEach((eq: { newareId: Number; id: any; }) => {
        if(eq.newareId==newareId){
          return eq.id
        }
      });
  }
 
  ngOnInit(): void {

    this.cellFamilyRefs = {
      manufacturers: [],
      chemistries:[]
    }
    
    new DbServiceService<any, any>(this.http, environment.server_host+"/api/protocols", this.cookieService).getAll().subscribe(protocols=>{
      this.protocols = protocols
    })
    


    this.testEquipments = [
      {id:33, channels:4, ipAddress:"", newareId: 20033},
      {id:34, channels:4, ipAddress:"", newareId: 20034},
      {id:11, channels:4, ipAddress:"", newareId: 20011},
      {id:12, channels:4, ipAddress:"", newareId: 20012},
      {id:13, channels:4, ipAddress:"", newareId: 20013},
      {id:14, channels:4, ipAddress:"", newareId: 20014},
      {id:215, channels:4, ipAddress:"", newareId: 20215},
      {id:216, channels:4, ipAddress:"", newareId: 20216},
      {id:41, channels:8, ipAddress:"", newareId: 20041},
    ]

    

        
   this.resetTest()

  }

  private resetTest(){
    this.testConfig = {

      equipmentConfig:{
       
      },

      protocol: 65,

      chargeCRate: .3,

      dischargeCRate: 1,

      numberOfCycles: 1

    }

    this.tempConfig = {

      equipmentConfig:{
      },


    }
  }

  validateCellFamily(){
    var message = ""
    if(this.cellFamilyRefs['manufacturers'].length>1){
      message = "not all configured cells  belongs to same manufacturer!"
    }
    if(this.cellFamilyRefs['chemistries'].length>1){
      message = "not all configured cells  belongs to same chemistries!"
    }
    return message
  }

  validateDuplicate(nunamId:Number){
    var message = ""
    Object.entries(this.testConfig.equipmentConfig).forEach(prop=>{
     (prop[1] as Number[]).forEach(p=>{
        if(p==nunamId){
          message = "given Nunam Id already added in the current Test"
        }
     })
    })
    return message
  }

  createSummary(){

  }



  private generateChannels(count:number):any[]{
    var channles= []
    for(var v = 0; v<count;v++){
      channles.push(null)
    }
    return channles
  }

  public addMachine(){
    if(this.objectKeys(this.testConfig['equipmentConfig']).indexOf(this.selectedMachineId+"")!=-1){
      alert("Selected Machine already added!")
    }else{
      var cCount = 0
      this.testEquipments.forEach((eq: { [x: string]: string; }) => {
        if(eq['newareId']==this.selectedMachineId+""){
          cCount = +eq['channels']
        }
      });
      this.testConfig['equipmentConfig'][this.selectedMachineId.valueOf()] = this.generateChannels(cCount)
      this.tempConfig['equipmentConfig'][this.selectedMachineId.valueOf()] = this.generateChannels(cCount)
      console.log(this.testConfig)
    }
  }

  public removeMachine(machine:any){
    delete this.testConfig['equipmentConfig'][machine]
    delete this.tempConfig['equipmentConfig'][machine]
  }

  public saveTest(){
    var message = this.validateCellFamily()
    if (message == ""){
      var links: { cellId: Number; channelId: number; unitId: string; }[] = []
      Object.entries(this.testConfig.equipmentConfig).forEach(prop=>{
        var c = 1;
        ((prop[1] as unknown) as Number[]).forEach(val=>{
          if(val!=null){
            var link = {
              cellId: val,
              channelId: c,
              unitId: prop[0]
            }
            links.push(link)
            c=c+1
          }
        })
       })
       var testConfig = {
        protocolId: this.testConfig.protocol,
        chargeCRate: this.testConfig.chargeCRate,
        dischargeCRate: this.testConfig.dischargeCRate,
        numberOfCycles: this.testConfig.numberOfCycles,
        batchId: this.testConfig.batchId,
        cellTestLinks: links
      }
      new DbServiceService<any, any>(this.http, environment.server_host+"/api/test-details", this.cookieService).add(testConfig).subscribe(data=>{
        alert("test added successfully, please start it in test explore screen after the test is being started in NEWARE CLIENT")
        this.resetTest()
      },err=>{
        alert("something went wrong please try again later")
      })
    }else{
      alert(message)
    }
  }

  public addCell(equipment:any, channel:any){
    console.log("chnlId" + channel)
    new DbServiceService<any,any>(this.http, environment.server_host+"/api/cells", this.cookieService).get(this.tempConfig['equipmentConfig'][equipment][channel]).subscribe(cell=>{
      var message = this.validateDuplicate(this.tempConfig['equipmentConfig'][equipment][channel])
      if(message==""){
        this.testConfig['equipmentConfig'][equipment][channel] = this.tempConfig['equipmentConfig'][equipment][channel] 
        
        //chemistries:[]
        if (cell['manufacturerId']!=null && this.cellFamilyRefs['manufacturers'].indexOf(cell['manufacturerId'])==-1){
          this.cellFamilyRefs['manufacturers'].push(cell['manufacturerId'])
        }
        if (cell['chemistryId']!=null && this.cellFamilyRefs['chemistries'].indexOf(cell['chemistryId'])==-1){
          this.cellFamilyRefs['chemistries'].push(cell['chemistryId'])
        }
      }else{
        alert(message)
        this.tempConfig['equipmentConfig'][equipment][channel] = null
      }

    }, err=>{
      if(err.statu == 404){
        alert("No cell with given nunam Id")
      }else{
        alert("cannot add cell of given nunam Id")
      }
      this.tempConfig['equipmentConfig'][equipment][channel] = null
      
    })
    
  }

  public removeCell(equipment:any, channel:any){
    this.testConfig['equipmentConfig'][equipment][channel] = null
  }



  public closeResult!:String
  private scannerInstance!:any

  public value!:any
  public valueToUpdate!:String

  public modalRef!:any

  public switch_cam(){
   
    if(this.scannerInstance.data._value!=null){
      console.log(this.scannerInstance)
      if(this.scannerInstance.config.activeDevice == 1){
        this.scannerInstance.config.activeDevice = 0
        this.scannerInstance.config.deviceActive = 0
        this.scannerInstance.deviceActive = 0
      }else{
        this.scannerInstance.config.activeDevice = 1
        this.scannerInstance.config.deviceActive = 1
        this.scannerInstance.deviceActive = 1
      }
      this.scannerInstance.stop()
      this.scannerInstance.start()
      console.log(this.scannerInstance)
    }
    
  }

  public on_scan_result(){
    if(this.scannerInstance.data._value!=null && this.scannerInstance.data._value.length>0){
      var v = this.scannerInstance.data._value[0].value
      this.tempConfig['equipmentConfig'][this.scanMachine][this.scanChannel] = v
      this.addCell(this.scanMachine, this.scanChannel)
      if(this.scannerInstance.isStart){
        this.scannerInstance.stop()
        this.modalRef.close()
      }
    }
    
  }

  public scan(scan:any){
    this.scannerInstance = scan
    this.scannerInstance.start()
    
    
  }
  private scanMachine:any
  private scanChannel:any

  public open_scanner(content:any, toUpdate:String, key:any, index:any) {
    this.scanChannel = index
    this.scanMachine = key
    this.valueToUpdate = toUpdate
    this.modalRef = this.modalService.open(content)
    console.log(this.scnnerComponent)
    this.modalRef.result.then((result:any) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason:any) => {
      this.closeResult = 
         `Dismissed ${this.getDismissReason(reason)}`;
    });  
    
    
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public validateCell(nunamId:Number){
    
  }

  

}
