import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DbServiceService<E,T> {

  constructor(protected http:HttpClient, protected link:String,  protected cookieService:CookieService) {
  }

  getAll():Observable<E[]>{
    const headerDict = {
      'Accept': 'application/json',
      'Authorization': "Bearer "+ this.cookieService.get("access-token") 
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    return this.http.get<E[]>(this.link.valueOf(),requestOptions)
  }

  get(id:T):Observable<E>{
    const headerDict = {
      'Accept': 'application/json',
      'Authorization': "Bearer "+ this.cookieService.get("access-token") 
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    return this.http.get<E>(this.link+"/"+id,requestOptions)
  }

  getWithoutId():Observable<E>{
    const headerDict = {
      'Accept': 'application/json',
      'Authorization': "Bearer "+ this.cookieService.get("access-token") 
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    return this.http.get<E>(this.link.valueOf(),requestOptions)
  }

  getAllCustom(path:string):Observable<E[]>{
    const headerDict = {
      'Accept': 'application/json',
      'Authorization': "Bearer "+ this.cookieService.get("access-token") 
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    return this.http.get<E[]>(path,requestOptions)
  }

  add(value:any):Observable<E>{
    const headerDict = {
      'Accept': 'application/json',
      'Authorization': "Bearer "+ this.cookieService.get("access-token") ,
      'Content-Type': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    const body=JSON.stringify(value);
    return this.http.post<E>(this.link.valueOf(),body,requestOptions)
  }

  update(value:any):Observable<E>{
    const headerDict = {
      'Accept': 'application/json',
      'Authorization': "Bearer "+ this.cookieService.get("access-token") ,
      'Content-Type': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    return this.http.put<E>(this.link.valueOf(),value,requestOptions)
  }

  delete(id:T){
    const headerDict = {
      'Authorization': "Bearer "+ this.cookieService.get("access-token") ,
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    return this.http.delete<E>(this.link.valueOf()+"/"+id,requestOptions)
  }

}
