import { Component, OnInit } from '@angular/core';
import { DbServiceService } from 'src/app/shared/services/dbservice.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ChildEntity } from 'src/app/models/child-entity';
import { ThrowStmt } from '@angular/compiler';
import { element } from 'protractor';

declare function  greet():void

@Component({
  selector: 'app-test-explorer',
  templateUrl: './test-explorer.component.html',
  styleUrls: ['./test-explorer.component.css']
})
export class TestExplorerComponent implements OnInit {

  
  public tests:any[] = []
  public testStaus:any = []
  public s!:Number
  public e!:Number
  public itemsPerPage:Number = 50
  public pagesPerDiaplay:Number = 10
  public totalNumberOfPages!:Number
  public currentPage!:Number
  public message!:String
  public filter:any = {

  }
  public totalTests!:Number
  public rowExpand:any = {

  }
  public rowData:any = {

  }

  constructor(private http:HttpClient, private cookieService:CookieService, private route:ActivatedRoute) {
  }


  public protocols:ChildEntity[] = []
  
  

  public protocol:Number=-1
  public status:Number=-1

  public cellToUpdate:any

  public batchId!:Number
  public nunamId!:Number

  public out:Boolean = true


  public inProgress:Number = 0
  public completed:Number = 0
  public inUpload:Number = 0
  public processed:Number = 0


  back(){
    this.out = true
  }

  


  ngOnInit(): void {

    
    //greet()

    var msg = "{{((+currentPage-1)*+itemsPerPage)+1}} to {{((+currentPage-1)*+itemsPerPage)+itemsPerPage}}"
    this.filter = {}
    this.load(1)
    this.loadFilter()
    this.s = 1


    

    new DbServiceService<ChildEntity, Number>(this.http, environment.server_host+"/api/protocols", this.cookieService).getAll().subscribe(data=>{
      this.protocols = data
    })

    

    new DbServiceService<Number, Number>(this.http, environment.server_host+"/api/test-details/filter/count", this.cookieService).add({'status': 2}).subscribe(data=>{
      this.inProgress = data
    })

    new DbServiceService<Number, Number>(this.http, environment.server_host+"/api/test-details/filter/count", this.cookieService).add({'status': 3}).subscribe(data=>{
      this.completed = data
    })

    new DbServiceService<Number, Number>(this.http, environment.server_host+"/api/test-details/filter/count", this.cookieService).add({'status': 4}).subscribe(data=>{
      this.inUpload = data
    })

    new DbServiceService<Number, Number>(this.http, environment.server_host+"/api/test-details/filter/count", this.cookieService).add({'status': 9}).subscribe(data=>{
      this.processed = data
    })

   
    this.testStaus = {
      1: "Configured",
      2: "Running",
      3: "Finished",
      4: "Data Uploading",
      5: "Data Uploading",
      6: "Data Uploading",
      7: "CSV Generated",
      8: "ICA Calculated",
      9: "Metrics Calculated",
    }

  }

  public nextPages(){
    var c = +this.s + +this.pagesPerDiaplay
    if (c > this.totalNumberOfPages)
      return
    this.s = c
  }

  public prevPages(){
    if(this.s==1)
      return
    this.s = +this.s - +this.pagesPerDiaplay
  }

  public downloadICA(id:number){
    this.message = "Loading..."
    new DbServiceService<any, Number>(this.http, environment.server_host+"/api/ica-records?linkId="+id, this.cookieService).getAll().subscribe(data=>{
      var content = this.getCSVString(data)
      if(content!=null){
        let blob = new Blob(['\ufeff' + content], { 
          type: 'text/csv;charset=utf-8;'
        }); 
        let dwldLink = document.createElement("a"); 
        let url = URL.createObjectURL(blob);
        //if Safari open in new window to save file with random filename. 
        dwldLink.setAttribute("href", url); 
        dwldLink.setAttribute("download", "ica_"+id + ".csv"); 
        dwldLink.style.visibility = "hidden"; 
        document.body.appendChild(dwldLink); 
        dwldLink.click(); 
        document.body.removeChild(dwldLink); 
      }
      this.message = ""
    })
  }

  public downloadMetrics(id:number){
    //this.message = "Loading..."
    new DbServiceService<any, Number>(this.http, environment.server_host+"/api/test-metrics/"+id, this.cookieService).getAll().subscribe(data=>{
      var content = this.getMetricsCSVString(data)
      if(content!=null){
        let blob = new Blob(['\ufeff' + content], { 
          type: 'text/csv;charset=utf-8;'
        }); 
        let dwldLink = document.createElement("a"); 
        let url = URL.createObjectURL(blob);
        //if Safari open in new window to save file with random filename. 
        dwldLink.setAttribute("href", url); 
        dwldLink.setAttribute("download", "metrics_"+id + ".csv"); 
        dwldLink.style.visibility = "hidden"; 
        document.body.appendChild(dwldLink); 
        dwldLink.click(); 
        document.body.removeChild(dwldLink); 
      }
      //this.message = ""
    })
  }
  getMetricsCSVString(metrics: any) {
    var keys = Object.keys(metrics['metrics'])
    var str = ""
    keys.forEach(element=>{
      str = str+element+","
    })
    str += "\r\n"
    keys.forEach(element=>{
      str = str+(metrics['metrics'][element])+","
    })
    return str
    
  }


  public load(pageNumber:any){
    this.tests = []
    this.message = "Loading..."
    new DbServiceService<any, Number>(this.http, environment.server_host+"/api/test-details/filter?offset="+((+pageNumber-1)*+this.itemsPerPage)+"&limit="+this.itemsPerPage, this.cookieService).add(this.filter).subscribe(data=>{
      this.tests = data
      for(var v=0;v<this.tests.length;v++){
        this.rowExpand[v] = false
      }
      this.tests.forEach(test=>{
        this.rowData[test.id] = null
      })
      this.tests.push({})
      this.message = ""
      this.currentPage = pageNumber
    })
  }

  public rowClick(index:number, testId:number){
    this.rowExpand[index] = !this.rowExpand[index]
    if(this.rowExpand[index]){
      if(this.rowData[testId]==null){
        new DbServiceService(this.http, environment.server_host+"/api/cell-test-links/filter?orderBy=cell", this.cookieService).add({"testDetails": testId}).subscribe(data=>{
          this.rowData[testId] = data
        })
      }
    }
  }

  public setFilter(){
    this.filter = {}
    if(this.protocol!=-1){
      this.filter['protocol'] = +this.protocol
    }
    if(this.status!=-1){
      this.filter['status'] = +this.status
    }
    if((this.batchId != null && +this.batchId!=0) || (+this.nunamId!=0 &&this.nunamId != null)){
      this.filter = {}
      if(this.batchId != null){
        this.filter['id'] = +this.batchId
      }
      if(this.nunamId != null){
        this.filter['cell'] = +this.nunamId
      }
    }
    
    this.loadFilter()
    this.load(1)
  }

  public loadFilter(){  
    new DbServiceService<Number, Number>(this.http, environment.server_host+"/api/test-details/filter/count", this.cookieService).add(this.filter).subscribe(data=>{
      this.totalTests = +data
      var totalPages = +this.totalTests/+this.itemsPerPage
      this.totalNumberOfPages = Number(totalPages.toFixed(0))
      if(+totalPages%1!=0){
        this.totalNumberOfPages  = +this.totalNumberOfPages + 1
      }
    })
  }

  private getCSVString(cells:any[]){
    if(cells.length>0){
      var str = ""
      var cols = [ "ts","relativeTs","current","voltage","capacity","dqByDv","dvByDq"]
      str = "ts,relativeTs,current,voltage,capacity,dqByDv,dvByDq\r\n"
      cells.forEach(cell => {
        var row = ""  
        cols.forEach(element => {

          if(cell[element] != null){
            row += (cell[element]+", ")
          }else{
            row += ","
          }
        });
        row.substring(0, row.length-1)
        row+="\r\n"
        str+=row
      });
      return str
      }else{
        alert("no data to download")
        return null
      }
  }

  startTest(id:number){
    if(confirm("Please Make sure the test is alreasy started in Neware")){
      new DbServiceService(this.http, environment.server_host+"/api/test-details/start/"+id, this.cookieService).add({}).subscribe(data=>{
        this.tests.forEach(test=>{
          if(test.id==id){
            test.status = 2
          }
        })
        alert("Test started successfully!")
      }, err=>{
        alert("Something went wrong, test cannot be started now")
      })
    }
  }

  stopTest(id:number){
    if(confirm("Please Make sure the test is alreasy stopped in Neware")){
      new DbServiceService(this.http, environment.server_host+"/api/test-details/stop/"+id, this.cookieService).add({}).subscribe(data=>{
        this.tests.forEach(test=>{
          if(test.id==id){
            test.status = 3
          }
        })
        alert("Test stoped successfully!")
      }, err=>{
        alert("Something went wrong, test cannot be stopped now")
      })
    }
  }
 
}
