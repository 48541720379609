<div class="row">
    <div class="col-9">
        <h2>Nunam BMS</h2>
    </div>
    <div class="col-3" style="text-align: left;">
        <button class="btn btn-lg btn-outline-primary" (click)="open(mymodal)">+</button>
    </div>
</div>
<div class="row">
    <div class="col-5"><b>Id</b></div>
    <div class="col-5"><b>Name</b></div>
    <div class="col-2"><b>Action</b></div>
</div>
    
<div class="row" style="margin-top: 10px;margin-bottom: 10px;" *ngFor="let bms of nunamBmses">
    <div class="col-5" style="text-align: left;">{{bms.id}}</div>
    <div class="col-5"  style="text-align: left;"><input type="text" [(ngModel)]="bms.name" placeholder="Name" style="width: 100%;"></div>
    <div class="col-2"  style="text-align: left;"><button (click)="delete(bms.id)">X</button><button (click)="update(bms.id)">U</button></div>
</div>
    
<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add BMS</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12"><input type="text" placeholder="Name" [(ngModel)]="newBms.name" style="width: 100%;"/></div>
            <div class="col-12"><button (click)="add()">Add</button></div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
    </div>
</ng-template>
    