<div class="parent">
    <button (click)="open(mymodal)">Add</button>
    <table mat-table [dataSource]="values">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>  ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="numberOfCells">
            <th mat-header-cell *matHeaderCellDef> {{name}} </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="operations">
            <th mat-header-cell *matHeaderCellDef> Operations </th>
            <td mat-cell *matCellDef="let element"> <button (click)="openToUpdate(mymodal, element)">Update</button><button (click)="delete(element.id)">Delete</button> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ng-template #mymodal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12"><input type="text" placeholder="Name" [(ngModel)]="valToCreate.name" style="width: 100%;"/></div>
                <div class="col-12" *ngIf="!isUpdate"><button (click)="add()">Add</button></div>
                <div class="col-12" *ngIf="isUpdate"><button (click)="update()">Update</button></div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
        </div>
    </ng-template>
</div>
