export class Cell {
    nunamId!:Number
    cellId!:String
    cellWeight!:Number
    acir!:Number
    time!:Date
    nunamScore!:Number
    nominalVoltage!:Number
    cellVoltage!:Number
    comments!:String
    width!:Number
    nominalCapacity!:Number

    packId!:Number
    stringId!:Number
    chemistryId!:Number
    manufacturerId!:Number
    cellTypeId!:Number
    cellStageId!:Number
    eWasteId!:Number
    bmsId!:Number
    cellModelId!:Number

    physicalConditions:any = []
    physicalConditionsIds:Number[] = []
    conditions!:String[]    
    cellCondition!:String
    status!:String

    ocv!:Number
   
}
