import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Protocol } from './protocol';
import {RestService} from '../rest-service.service'
import { CookieService } from 'ngx-cookie-service';
import { ProtocolType } from './protocol-type';

@Component({
  selector: 'app-protocol',
  templateUrl: './protocol.component.html',
  styleUrls: ['./protocol.component.css']
})
export class ProtocolComponent implements OnInit {

  constructor(private modalService:NgbModal, private restService:RestService<Protocol>, private cookieService:CookieService, private typeService:RestService<ProtocolType>) { 
    this.newProtocol = new Protocol()
    this.refresh()
  }
  protocols!:Protocol[]
  newProtocol!:Protocol
  closeResult!:String
  protocolTypes!:ProtocolType[]
  ngOnInit(): void {

  }

  open(content:any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  add(){
    this.restService.add("Bearer "+this.cookieService.get("access-token"), "api/protocols", this.newProtocol).subscribe(data=>{
      alert("protocl added");
      this.refresh()
    }, error=>{
      alert("couldn't add something went wrong");
    })
  }

  delete(id:Number){
    if(confirm("Are you sure to delete the protocol Id: "+id)) {
      this.restService.delete("Bearer "+this.cookieService.get("access-token"), "api/protocols/"+id).subscribe(data=>{
        alert("protocol "+ id +" delete ")
        this.refresh();
      }, error=>{
        alert("couldn't delete something went wrong");
      })
    }
  }

  refresh(){
    this.newProtocol = new Protocol()
    this.typeService.getAll("Bearer "+ this.cookieService.get("access-token"), "api/protocol-types").subscribe(types=>{
      this.protocolTypes = types
      this.restService.getAll("Bearer "+this.cookieService.get("access-token"), "api/protocols").subscribe(data=>{
        this.protocols = data;
      })
    })
  }

  getTypeName(type:ProtocolType):String{
    if(type==null){
      return "";
    }
    return type.protocolTypeName
  }
  

}
