<div class="row">
    <div class="col-9">
        <h2>Protocol</h2>
    </div>
    <div class="col-3" style="text-align: left;">
        <button class="btn btn-lg btn-outline-primary" (click)="open(mymodal)">+</button>
    </div>
</div>
<div class="row">
    <div class="col-1"><b>Id</b></div>
    <div class="col-3"><b>Name</b></div>
    <div class="col-3"><b>Type</b></div>
    <div class="col-4"><b>Pulse Position</b></div>
    <div class="col-1"><b>Action</b></div>
</div>
    
<div class="row" style="margin-top: 10px;margin-bottom: 10px;" *ngFor="let protocol of protocols">
    <div class="col-1" style="text-align: left;">{{protocol.protocolId}}</div>
    <div class="col-3"  style="text-align: left;">{{protocol.protocolName}}</div>
    <div class="col-3"  style="text-align: left;">{{getTypeName(protocol.protocolType)}}</div>
    <div class="col-4"  style="text-align: left;">{{protocol.pulsePosition}}</div>
    <div class="col-1"  style="text-align: left;"><a [routerLink]="['/protocol-management', protocol.protocolId]" class="btn btn-info">=></a><button (click)="delete(protocol.protocolId)">X</button></div>
</div>
    
<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Protocol</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12"><input type="text" placeholder="Id" [(ngModel)]="newProtocol.protocolId" style="width: 100%;"/></div>
            <div class="col-12"><input type="text" placeholder="Name" [(ngModel)]="newProtocol.protocolName" style="width: 100%;"/></div>
            <select name="protocolType" id="protocolTypeId" class="form-control" [(ngModel)]="newProtocol.protocolTypeId">
                <option *ngFor="let type of protocolTypes" [value]="type.id">
                    {{type.protocolTypeName}}
                </option>
            </select>
            <div class="col-12"><input type="text" placeholder="Pulse Position" [(ngModel)]="newProtocol.pulsePosition" style="width: 100%;"/></div>
            <div class="col-12"><button (click)="add()">Add</button></div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
    </div>
</ng-template>