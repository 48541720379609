import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestService<T> {

  private restHost!:string
  private arr!:String[]
  constructor(private http:HttpClient) { 
    this.restHost = environment.server_host

  }

  public getAll(accessToken:string, url:string):Observable<T[]>{
    const headerDict = {
      'Accept': 'application/json',
      'Authorization': accessToken 
    }
    const requestOptions = {                                                                                                                                                                                  
      headers: new HttpHeaders(headerDict)
    };
     return this.http.get<T[]>(this.restHost+url,requestOptions);
   }

   public get(accessToken:string, url:string):Observable<T>{
    const headerDict = {
      'Accept': 'application/json',
      'Authorization': accessToken 
    }
    const requestOptions = {                                                                                                                                                                                  
      headers: new HttpHeaders(headerDict)
    };
     return this.http.get<T>(this.restHost+url,requestOptions);
   }

   public add(accessToken:string, url:string, value:T):Observable<T>{
    const headerDict = {
      'Accept': 'application/json',
      'Authorization': accessToken ,
      'Content-Type': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                  
      headers: new HttpHeaders(headerDict)
    };
    const body=JSON.stringify(value);
     return  this.http.post<T>(this.restHost+url, body, requestOptions)
   }

   public update(accessToken:string, url:string, value:T):Observable<T>{
    const headerDict = {
      'Accept': 'application/json',
      'Authorization': accessToken ,
      'Content-Type': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                  
      headers: new HttpHeaders(headerDict)
    };
    const body=JSON.stringify(value);
     return  this.http.put<T>(this.restHost+url, body, requestOptions)
   }

   public delete(accessToken:string, url:string):Observable<any>{
    const headerDict = {
      'Authorization': accessToken ,
    }
    const requestOptions = {                                                                                                                                                                                  
      headers: new HttpHeaders(headerDict)
    };
    return  this.http.delete<T>(this.restHost+url, requestOptions)
   }
 
}
