<div class="main">
    <div class="row content filter">
        <div class="col-6">Machine</div>
        <div class="col-6">
            <select [(ngModel)]="machineId" (change)="selectMachine()">
                <option value=-1>-select-</option>
                <option *ngFor="let machine of machines" value="{{machine.id}}">{{machine.name}}</option>
            </select>
        </div>
        
        <div class="col-6">Channel</div>
        <div class="col-6">
            <select [(ngModel)]="channel" >
                <option value=-1>-select-</option>
                <option *ngFor="let c of channels" value="{{c}}">{{c}}</option>
            </select>
        </div>

        <div class="col-6">Start Time</div>
        <div class="col-6">
            <input type="test" placeholder="start time" [(ngModel)]="startTime"/>
        </div>

        <div class="col-6">End Time</div>
        <div class="col-6">
            <input type="test" placeholder="end time" [(ngModel)]="endTime"/>
        </div>

        <div class="col-12" style="text-align: center;">
            <button (click)="applyFilter()">Apply</button>
        </div>
    </div>

    <div class="row content" >
        <div class="col-6" style="text-align: right;">Total Tests</div>
        <div class="col-6" style="text-align: left;">{{totalTests}}</div>
    </div>
    
    <div class="row content data">
        <div class="col-12 table">
            <div class="row" style="background-color: rgb(114, 112, 116);">
                <div class="col-2">Machine</div>
                <div class="col-1">Channel</div>
                <div class="col-2">Start Time</div>
                <div class="col-2">End Time</div>
                <div class="col-1">Test Id</div>
                <div class="col-2">Test Number</div>
                <div class="col-1">Status</div>
                <div class="col-1">Data</div>
            </div>
            <div class="row" *ngFor="let test of tests; let i = index" [ngClass]="{'even-row': i%2==0, 'odd-row': i%2==1}">
                <div class="col-2">{{test.newareTestId.testEquipmentId}}</div>
                <div class="col-1">{{test.channelId}}</div>
                <div class="col-2">{{getDateString(test.startTime*1000)}}</div>
                <div class="col-2">{{getDateString(test.endTime*1000)}}</div>
                <div class="col-1">{{test.testId}}</div>
                <div class="col-2">{{test.newareTestId.testNumber}}</div>
                <div class="col-1">{{statusMap[test.status]}}</div>
                <div class="col-1"><button *ngIf="test.status >= 3" class=".btn" (click)="download(test.newareTestId)">Download</button></div>
            </div>
        </div>
    </div>

    <div class="row content pagination">
        <div class="col-12" style="text-align: center;">
            <ul>
                <li (click)="prevPages()"><a><-PREV</a></li>
                <li *ngFor="let item of [].constructor(pagesPerDiaplay); let i = index" (click)="load((+i)+(+s))">
                  <a>{{(+i)+(+s)}}</a>
                </li>
                <li (click)="nextPages()"><a>NEXT-></a></li>
            </ul>
        </div>
        <div class="col-12">
            <h5 style="text-align: center;">Page {{s}} to {{(+s)+(+pagesPerDiaplay)-1}} of {{totalNumberOfPages}}</h5>    
        </div>
        
    </div>
</div>


