import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {AppComponent} from './../app.component'
import { AuthService } from './auth.service';



@Component({
  selector: 'app-login',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  public userName!:string
  public password!:string
  loginMessage!:String
  auth!:AuthService
  constructor(authS:AuthService, private cookieService: CookieService, private router: Router,private app:AppComponent) {
    this.auth =authS
    this.loginMessage = "no one logged"
   }

  ngOnInit(): void {
  }

  login(){
    var response = this.auth.login(this.userName,this.password)
    response.subscribe(data => {
      AppComponent.userName =  this.userName
      this.cookieService.set( 'access-token', data.access_token.valueOf())
      this.cookieService.set( 'loggedin-user', this.userName)
      this.router.navigateByUrl('/settings');
      this.app.isLogged=true
    },
    error => {
      alert("Login Failed!... Invalid Credentials")
    });
  }

}
