<div class="row">
    <div class="col-md-6" style="text-align: right;">
    User Name
    </div>
    <div class="col-md-6" style="text-align: left;">
        <input  type="text" [(ngModel)]="userName"/>
    </div>
    <div class="col-md-6" style="text-align: right;">
        Password
    </div>
    <div class="col-md-6">
        <input type="password" [(ngModel)]="password" style="text-align: left;"/>
    </div>
    <div class="col-md-12" style="text-align: center;">
        <button (click)="login()">Login</button>
    </div>
</div>
