<div class="row">
    <div class="col-9">
        <h2>Protocol</h2>
    </div>
    <div class="col-3" style="text-align: left;">
        <button class="btn btn-lg btn-outline-primary" (click)="open(mymodal)">+</button>
    </div>
</div>
<div class="row">
    <div class="col-1"><b>Id</b></div>
    <div class="col-3"><b>Created Date</b></div>
    <div class="col-2"><b>Type</b></div>
    <div class="col-2"><b>Status</b></div>
    <div class="col-3"><b>Bms</b></div>
    <div class="col-1"><b>Actions</b></div>
</div>
    
<div class="row" style="margin-top: 10px;margin-bottom: 10px;" *ngFor="let pack of nunamPacks">
    <div class="col-1" style="text-align: left;">{{pack.id}}</div>
    <div class="col-3"  style="text-align: left;">{{pack.createdDate}}</div>
    <div class="col-2"  style="text-align: left;">{{pack.type}}</div>
    <div class="col-2"  style="text-align: left;">{{pack.status}}</div>
    <div class="col-3"  style="text-align: left;">{{pack.bmsName}}</div>
    <div class="col-1"  style="text-align: left;"><button (click)="delete(pack.id)">X</button></div>
</div>
    
<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Protocol</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">Pack Type</div>
            <div class="col-12">
                <select name="packType" id="packTypeId" class="form-control" [(ngModel)]="newPack.typeId">
                    <option *ngFor="let type of packTypes" [value]="type.id">
                        {{type.type}}
                    </option>
                </select>
            </div>
            <div class="col-12">Pack Status</div>
            <div class="col-12">
                <select name="packStatus" id="packStatusId" class="form-control" [(ngModel)]="newPack.statusId">
                    <option *ngFor="let status of packStatus" [value]="status.id">
                        {{status.status}}
                    </option>
                </select>
            </div>
            <div class="col-12">BMS</div>
            <div class="col-12">
                <select name="packBms" id="packBmsId" class="form-control" [(ngModel)]="newPack.bmsId">
                    <option *ngFor="let bms of bmses" [value]="bms.id">
                        {{bms.name}}
                    </option>
                </select>
            </div>
            <div class="col-12"><button (click)="add()">Add</button></div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
    </div>
</ng-template>
    

