import { ProtocolType } from './protocol-type';

export class Protocol {

    protocolId!:Number;
	protocolName!:String;
	cRate!:Number
	startV!:Number
	uv!:Number
	lv!:Number
	pulseNo!:Number
	pulsePosition!:String
	opStatus!:Number
	comments!:String
	stepsNo!:Number
	developerId!:Number	
    protocolTypeId!:Number
	protocolType!:ProtocolType
	jsonData!:any
}
