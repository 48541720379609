import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-qrscanner',
  templateUrl: './qrscanner.component.html',
  styleUrls: ['./qrscanner.component.css']
})
export class QRScannerComponent implements OnInit {

  constructor(private modalService:NgbModal) { }

  @ViewChild('content') myId!: ElementRef

  ngOnInit(): void {
   this.openScanner()
  }


  public closeResult!:String
  private scannerInstance!:any

  public value!:any
  public valueToUpdate!:String

  public modalRef!:any

  public switch_cam(){
   
    if(this.scannerInstance.data._value!=null){
      console.log(this.scannerInstance)
      if(this.scannerInstance.config.activeDevice == 1){
        this.scannerInstance.config.activeDevice = 0
        this.scannerInstance.config.deviceActive = 0
        this.scannerInstance.deviceActive = 0
      }else{
        this.scannerInstance.config.activeDevice = 1
        this.scannerInstance.config.deviceActive = 1
        this.scannerInstance.deviceActive = 1
      }
      this.scannerInstance.stop()
      this.scannerInstance.start()
      console.log(this.scannerInstance)
    }
    
  }

  public on_scan_result(){
    if(this.scannerInstance.data._value!=null && this.scannerInstance.data._value.length>0){
      var v = this.scannerInstance.data._value[0].value
      alert(v)
      if(this.scannerInstance.isStart){
        this.scannerInstance.stop()
        this.modalRef.close()
      }
    }
    
  }

  public scan(scan:any){
    this.scannerInstance = scan
    console.log(this.scannerInstance)
    
    
  }
  private scanMachine:any
  private scanChannel:any

  public openScanner() {
    this.modalRef = this.modalService.open(this.myId.nativeElement, )
    this.modalRef.result.then((result:any) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason:any) => {
      this.closeResult = 
         `Dismissed ${this.getDismissReason(reason)}`;
    });  

    
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
