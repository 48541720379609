import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ProtocolComponent } from './protocol/protocol.component';
import { AuthComponent } from './auth/auth.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProtocolManagementComponent } from './protocol/protocol-management/protocol-management.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { NunamPackComponent } from './nunam-pack/nunam-pack.component';
import { NunamBmsComponent } from './nunam-bms/nunam-bms.component';
import { TestDetailComponent } from './test-detail/test-detail/test-detail.component';
import { TestEquipmentComponent } from './test-equipment/test-equipment/test-equipment.component';
import { TestDetailManagementComponent } from './test-detail/test-detail-management/test-detail-management.component';
import { DismantlingComponent } from './dismantling/dismantling.component';
import { CellComponent } from './cell/cell/cell.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations' 
import {MatExpansionModule} from '@angular/material/expansion';
import { PackComponent } from './pack/pack.component';
import { MatTableModule } from '@angular/material/table';
import { ManyToOneComponent } from './many-to-one/many-to-one.component';
import { SettingsComponent } from './settings/settings.component';
import { PackDetailsComponent } from './pack/pack-details/pack-details.component';
import { StringComponent } from './shared/components/string/string.component';
import {MatRadioModule} from '@angular/material/radio';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input/';
import { AddStringsComponent } from './shared/components/add-strings/add-strings.component';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import { ModuleComponent } from './shared/components/module/module.component';
import { AddModuleComponent } from './shared/components/add-module/add-module.component';
import { AddCellComponent } from './cell/add-cell/add-cell.component';
import { ConductTestComponent } from './testing-components/conduct-test/conduct-test.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { TestExplorerComponent } from './testing-components/test-explorer/test-explorer.component';
import { QRScannerComponent } from './shared/qrscanner/qrscanner.component';
import { NewareTestsComponent } from './testing-components/neware-tests/neware-tests.component';



@NgModule({
  declarations: [
    AppComponent,
    ProtocolComponent,
    AuthComponent,
    ProtocolManagementComponent,
    NunamPackComponent,
    NunamBmsComponent,
    TestDetailComponent,
    TestEquipmentComponent,
    TestDetailManagementComponent,
    DismantlingComponent,
    CellComponent,
    PackComponent,
    ManyToOneComponent,
    SettingsComponent,
    PackDetailsComponent,
    StringComponent,
    AddStringsComponent,
    ModuleComponent,
    AddModuleComponent,
    AddCellComponent,
    ConductTestComponent,
    TestExplorerComponent,
    QRScannerComponent,
    NewareTestsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgJsonEditorModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatTableModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatButtonModule,
    NgxScannerQrcodeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
