<div class="row" style="width: 100%;">
  <div class="col-md-10" style="text-align: center;"><h1>Nunam Internal UI</h1></div>
  <div class="col-md-1" style="text-align: right;">{{getUserName()}}</div>
  <div class="col-md-1" style="text-align: left;" *ngIf="isLogged"><button (click)="logout()">Logout</button></div>
</div>
<div class="row" style="width: 100%;">
<div class="col-md-2">
  <div class="row" *ngIf="isLogged">
    <div class="col-12">
      <ul>
        <li>
          <input type="checkbox" id="list-item-1">
          <label for="list-item-1" class="first btn btn-info">Dismantling</label>
          <ul>
            <li><a [routerLink]="['/dismantling']" class="btn btn-info" style="width: 100%;">Dismantle Pack</a></li>
            <li><a [routerLink]="['/pack']" class="btn btn-info" style="width: 100%;">Explaore Pack</a></li>
            <li><a [routerLink]="['/add-cells']" class="btn btn-info" style="width: 100%;">Add Cell</a></li>
            <li><a [routerLink]="['/cells']" class="btn btn-info" style="width: 100%;">Explore Cell</a></li>
            <li><a [routerLink]="['/settings']" class="btn btn-info" style="width: 100%;">Settings</a></li>
          </ul>
        </li>
        <li>
          <input type="checkbox" id="list-item-2">
          <label for="list-item-2" class="btn btn-info">Testing</label>
          <ul>
            <li><a [routerLink]="['/start-test']" class="btn btn-info" style="width: 100%;">Conduct Test</a></li>
            <li><a [routerLink]="['/explore-tests']" class="btn btn-info" style="width: 100%;">Tests By Batch</a></li>
            <li><a [routerLink]="['/neware-tests']" class="btn btn-info" style="width: 100%;">Tests By Machine</a></li>
          </ul>
        </li>
        <li>
          <input type="checkbox" id="list-item-3">
          <label for="list-item-3" class="last btn btn-info">Assembling</label>
          <ul>
            <li><a [routerLink]="['/start-test']" class="btn btn-info" style="width: 100%;">Cell Grouping</a></li>
            <li><a [routerLink]="['/explore-tests']" class="btn btn-info" style="width: 100%;">Distance Matrix</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  
</div>
<div class="col-md-10">
  <router-outlet></router-outlet>
</div>
0