import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { DbServiceService } from 'src/app/shared/services/dbservice.service';
import { TestDetail } from '../test-detail';

@Component({
  selector: 'app-test-detail',
  templateUrl: './test-detail.component.html',
  styleUrls: ['./test-detail.component.css']
})
export class TestDetailComponent implements OnInit {

  testDetailService!:DbServiceService<TestDetail,Number>
  constructor(private cookieService:CookieService,http:HttpClient) {
    this.testDetailService = new DbServiceService(http, "http://192.168.1.157:8081/api/test-details",cookieService)
  }

  public testDetails!:TestDetailsRecord[]
  ngOnInit(): void {
    let map = new Map()
    this.testDetailService.getAll().subscribe(tests=>{
      tests.forEach(test => {
        var record = map.get(test.batchId)
        if(record == null){
          record = new TestDetailsRecord()
          record.testDetails = []
        }
        record.batchId = test.batchId
        record.numberOfRecord++;
        record.date = test.date
        record.testDetails.push(test)
        map.set(test.batchId, record)
      });
      this.testDetails = []
      for(let key of map.keys()){
        this.testDetails.push(map.get(key))
        window.localStorage.setItem(key+"", JSON.stringify(map.get(key)))
        
      }
      
    })
    
  }

  ngOnDestroy() {
    localStorage.clear()
  }


  delete(id:any){

  }

}

class TestDetailsRecord{
  public batchId!:Number
  public numberOfRecord:Number=0
  public date!:Date
  public testDetails!:TestDetail[]
}


