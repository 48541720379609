import { EWaste } from './ewaste'
import { BMS } from './bms'
import { PhysicalCondition } from './physical-condition'
import { ChildEntity } from './child-entity'

export class Pack {

    id!:Number
    nominalVoltage!:Number
    capacity!:Number
    outputWh!:Number
    packCondition!:String
    manufacturingYear!:Number
    manufacturingMonth!:Number
    laptopBrand!:String
    numberOfCells!:Number
    comments!:String
    time!:Date
    source!:String
    typeId!:Number
    currentVoltage!:Number
    weight!:Number
    nominalEnergy!:Number

    chemistry!:ChildEntity
    chemistryId!:Number

    eWaste!:EWaste
    eWasteId!:Number

    bms!:BMS
    bmsId!:Number

    bmsData!:Map<String,any>

    physicalConditions!:PhysicalCondition[]
    physicalConditionsIds:Number[] = []


}
