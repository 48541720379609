import { Component, OnInit, Input } from '@angular/core';
import { DbServiceService } from '../../services/dbservice.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Strings } from 'src/app/models/strings';
import { environment } from 'src/environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Cell } from 'src/app/models/cell';

@Component({
  selector: 'app-string',
  templateUrl: './string.component.html',
  styleUrls: ['./string.component.css']
})
export class StringComponent implements OnInit {

  @Input() stringId!:Number

  private stringService!:DbServiceService<Strings,Number>
  private closeResult!:string

  public strings!:Strings
  public cells!:Cell[]

  constructor(private http:HttpClient,private cookieService:CookieService, private modalService:NgbModal) {
    
  }
  
  ngOnInit(): void {
    this.strings = new Strings()
    this.synchData()
  }

  synchData(){
    this.stringService = new DbServiceService(this.http,environment.server_host+"/api/strings", this.cookieService)
    this.stringService.get(this.stringId).subscribe(data=>{
      this.strings = data
    })
  }

  open(content:any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  refresh(){
    this.modalService.dismissAll()
    this.ngOnInit()
  }

  
}
