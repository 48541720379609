import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-equipment',
  templateUrl: './test-equipment.component.html',
  styleUrls: ['./test-equipment.component.css']
})
export class TestEquipmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
