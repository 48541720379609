<div class="parent">
    <div class="row">
        <div class="col-6">
            <h2>Cell Types</h2><br>
            <app-many-to-one name="Cell Types" url="/api/cell-types"></app-many-to-one>
        </div>
        <div class="col-6">
            <h2>Cell Stages</h2>
            <app-many-to-one name="Cell Stages " url="/api/cell-stages"></app-many-to-one>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <h2>Chemistry</h2><br>
            <app-many-to-one name="Chemistries" url="/api/chemistries"></app-many-to-one>
        </div>
        <div class="col-6">
            <h2>E Waste Source</h2><br>
            <app-many-to-one name="E Waste Source" url="/api/e-wastes"></app-many-to-one>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <h2>Physical Conditions</h2><br>
            <app-many-to-one name="Physical Conditions" url="/api/physical-conditions"></app-many-to-one>
        </div>

        <div class="col-6">
            <h2>Manufacturer</h2><br>
            <app-many-to-one name="Manufacturer" url="/api/manufacturers"></app-many-to-one>
        </div>
    </div>
    <!-- Cell manufacturer, model no, ewaste source -->
</div>