<div class="main">
    <div class="row">
        <div class="col-6 menu-item">
            <h2 style="text-align: center;" (click)="switchMode(true)" [ngClass]="{'active':isSingleAdd}">Single</h2>
        </div>
        <div class="col-6 menu-item">
            <h2 style="text-align: center;" (click)="switchMode(false)" [ngClass]="{'active':!isSingleAdd}">Multiple</h2>
        </div>
        
    </div>
    
    <div class="row" style="width: 100%;padding: 50px;">
        <div class="col-6">Total Cells Added: {{totalCount}}</div>
        <div class="col-6">Recent Nunam Id: {{lastNunamId}}</div>
    </div>

    
    <div class="row" *ngIf="!isSingleAdd">
        <div class="col-12" style="text-align: center;">
            <b>{{message}}</b>
        </div>
        <div class="col-12">
            <div class="row top-menu" style="border-radius: 15px;background-color: rgb(73, 119, 138);margin-left:5%;width: 90%;padding: 20px;margin-bottom: 20px;" >
                <div class="col-3" style="color: white;padding: 5px;"><b>Start Nunam Id</b></div>
                <div class="col-3"><input type="text" [(ngModel)]="startNunamId"/></div>
                <div class="col-3" style="color: white;padding: 5px;">End Nunam Id</div>
                <div class="col-3"><input type="text" [(ngModel)]="endNunamId"/></div>
                <div class="col-6" style="text-align: center;"><button (click)="generateNewCells()">Generate</button></div>
                <div class="col-6" style="text-align: center;"><button (click)="resetMul()">Reset</button></div>
            </div>
        </div>
        
        <div class="col-12" style="overflow-x: scroll;">
                <table>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Nunam Id</th>
                            <th>Cell Id</th>
                            <th>Cell weight</th>
                            <th>Acir</th>
                            <th>Nunam Score</th>
                            <th>Nominal Voltage</th>
                            <th>Cell Voltage</th>
                            <th>Width</th>
                            <th>Nominal Capacity</th>
                            <th>Comments</th>
                            <th>Chemistry</th>
                            <th>Manufacturer</th>
                            <th>Type</th>
                            <th>E-Waste Source</th>
                            <th>Model</th>
                            <th>Physical Conditions</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cell of mulltipleAddCells" class="xl-row">
                            <td><span *ngIf="mulltipleAddCellsStatus[cell.nunamId]" style="color: green;">Saved</span><div *ngIf="!mulltipleAddCellsStatus[cell.nunamId]" style="color: red;border-radius: 50%;float: right;">Not Saved</div></td>
                            <td><input type="text" name="nunamId{{cell.nunamId}}" [(ngModel)]="cell.nunamId" disabled /></td>
                            <td><input type="text" name="cellId{{cell.nunamId}}" [(ngModel)]="cell.cellId" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null"  /></td>
                            <td><input type="text" name="cellWeight{{cell.nunamId}}" [(ngModel)]="cell.cellWeight" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null"/></td>
                            <td><input type="text" name="acir{{cell.nunamId}}" [(ngModel)]="cell.acir" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null"/></td>
                            <td><input type="text" name="nunamScore{{cell.nunamId}}" [(ngModel)]="cell.nunamScore" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null"/></td>
                            <td><input type="text" name="nominalVoltage{{cell.nunamId}}" [(ngModel)]="cell.nominalVoltage" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null"/></td>
                            <td><input type="text" name="cellVoltage{{cell.nunamId}}" [(ngModel)]="cell.cellVoltage" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null"/></td>
                            <td><input type="text" name="width{{cell.nunamId}}" [(ngModel)]="cell.width" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null"/></td>
                            <td><input type="text" name="nominalCapacity{{cell.nunamId}}" [(ngModel)]="cell.nominalCapacity" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null"/></td>
                            <td><input type="text" name="comments{{cell.nunamId}}" [(ngModel)]="cell.comments" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null"/></td>
                            <td>
                                <select name="{{cell.nunamId}}chemistryId" [(ngModel)]="cell.chemistryId" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null">
                                    <option *ngFor="let chemistry of chemistries" value="{{chemistry.id}}">{{chemistry.name}}</option>
                                </select>
                            </td>
                            <td>
                                <select name="{{cell.nunamId}}manufacturerId" [(ngModel)]="cell.manufacturerId" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null">
                                    <option *ngFor="let manufacturer of manufacturers" value="{{manufacturer.id}}">{{manufacturer.name}}</option>
                                </select>
                            </td>
                            <td>
                                <select name="{{cell.nunamId}}cellTypeId" [(ngModel)]="cell.cellTypeId" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null">
                                    <option *ngFor="let type of cellTypes" value="{{type.id}}">{{type.name}}</option>
                                </select>
                            </td>
                            <td>
                                <select name="{{cell.nunamId}}eWasteId" [(ngModel)]="cell.eWasteId" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null">
                                    <option *ngFor="let ewaste of eWastes" value="{{ewaste.id}}">{{ewaste.name}}</option>
                                </select>
                            </td>
                            <td>
                                <select name="{{cell.nunamId}}cellModelId" (change)="onChangeMPC(cell.nunamId, $event.target)" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null">
                                    <option *ngFor="let cellModel of cellModels" value="{{cellModel.id}}">{{cellModel.name}}</option>
                                </select>
                            </td>
                            <td>
                                <div *ngFor="let pc of cell.physicalConditionsIds" style="background-color: rgb(189, 100, 248);color: black;float: left;" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null">
                                    {{getCondition(pc)}}<span style="background-color: grey;padding: 3px;" (click)="removeMPC(cell.nunamId, pc)">X</span>
                                </div>
                                <select name="{{cell.nunamId}}physicalConditions" (change)="onChangeMPC(cell.nunamId, $event.target)" [attr.disabled]="mulltipleAddCellsStatus[cell.nunamId] ?'':null">
                                    <option *ngFor="let pc of packConditions" value="{{pc.id}}">{{pc.name}}</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style="width: 100%;text-align: center;">
                    <button (click)="addMultipleCells()">Add Cells</button>
                </div>
        </div>
    </div>
    <div class="row" style="padding-left: 100px;" *ngIf="isSingleAdd">
        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>Nunam ID</mat-label>
                <input matInput placeholder="id" [(ngModel)]="newCell.nunamId">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>Reference ID</mat-label>
                <input matInput placeholder="id" [(ngModel)]="newCell.cellId">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>ACIR</mat-label>
                <input matInput placeholder="id" [(ngModel)]="newCell.acir">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>Nominal Voltage</mat-label>
                <input matInput placeholder="id" [(ngModel)]="newCell.nominalVoltage">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>Cell Voltage</mat-label>
                <input matInput placeholder="id" [(ngModel)]="newCell.cellVoltage">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>Cell Weight(gm)</mat-label>
                <input matInput placeholder="id" [(ngModel)]="newCell.cellWeight">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>Cell Width(mm)</mat-label>
                <input matInput placeholder="id" [(ngModel)]="newCell.width">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>Nominal Capacity</mat-label>
                <input matInput placeholder="id" [(ngModel)]="newCell.nominalCapacity">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-form-field appearance="outline">
                <mat-label>Nunam Score</mat-label>
                <input matInput placeholder="id" [(ngModel)]="newCell.nunamScore">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-8">
            <mat-form-field appearance="outline">
                <mat-label>Comments</mat-label>
                <input matInput placeholder="id" [(ngModel)]="newCell.comments">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-12" style="padding: 5px;" >
            <div class="row">
                <div class="col-12"><h4 style="text-align: left;">Physical Condition</h4></div>
                <div class="col-3" *ngFor="let condition of packConditions" >
                    <div [ngClass]="{'active-card': newCell.physicalConditionsIds.indexOf(condition.id) > -1, 'c-card': newCell.physicalConditionsIds.indexOf(condition.id) == -1}" (click)="setPackCondition(condition)" >{{condition.name}}</div>
                </div>
            </div>
        </div>

        <div class="col-12" style="padding: 5px;" >
            <div class="row">
                <div class="col-12"><h4 style="text-align: left;">Cell Type</h4></div>
                <div class="col-3" *ngFor="let type of cellTypes" >
                    <div [ngClass]="{'active-card': newCell.cellTypeId == type.id, 'c-card': newCell.cellTypeId != type.id}" (click)="setCellType(type)" >{{type.name}}</div>
                </div>
            </div>
        </div>

        <div class="col-12" style="padding: 5px;">
            <div class="row">
                <div class="col-12"><h4 style="text-align: left;">Chemistry</h4></div>
                <div class="col-3" *ngFor="let chemistry of chemistries" >
                    <div [ngClass]="{'active-card': newCell.chemistryId == chemistry.id, 'c-card': newCell.chemistryId != chemistry.id}" (click)="setChemistry(chemistry)" >{{chemistry.name}}</div>
                </div>
            </div>
        </div>

        <div class="col-12" style="padding: 5px;" >
            <div class="row">
                <div class="col-12"><h4 style="text-align: left;">Manufacturer</h4></div>
                <div class="col-3" *ngFor="let manufacturer of manufacturers" >
                    <div [ngClass]="{'active-card': newCell.manufacturerId == manufacturer.id, 'c-card': newCell.manufacturerId != manufacturer.id}" (click)="setManufacturer(manufacturer)" >{{manufacturer.name}}</div>
                </div>
            </div>
        </div>

        <div class="col-12" style="padding: 5px;" *ngIf="newCell.manufacturerId != null && newCell.manufacturerId != -1">
            <div class="row">
                <div class="col-12"><h4 style="text-align: left;">Cell Model</h4></div>
                <div class="col-3" *ngFor="let model of cellModels" >
                    <div [ngClass]="{'active-card': newCell.cellModelId == model.id, 'c-card': newCell.cellModelId != model.id}" (click)="setModel(model)" >{{model.name}}</div>
                </div>
            </div>
        </div>

        <div class="col-12" style="padding: 5px;">
            <div class="row">
                <div class="col-12"><h4 style="text-align: left;">Ewaste Source</h4></div>
                <div class="col-3" *ngFor="let ewaste of eWastes" >
                    <div [ngClass]="{'active-card': newCell.eWasteId == ewaste.id, 'c-card': newCell.eWasteId != ewaste.id}" (click)="setEWaste(ewaste)" >{{ewaste.name}}</div>
                </div>
            </div>
        </div>

        

        <div calss="col-12" style="text-align: center;width: 100%;padding-bottom: 50px;">
            <button (click)="add()" style="width: 200px;">Add</button>
        </div>
    </div>    
</div>

