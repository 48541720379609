import { Component, OnInit, Input } from '@angular/core';
import { DbServiceService } from '../shared/services/dbservice.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-many-to-one',
  templateUrl: './many-to-one.component.html',
  styleUrls: ['./many-to-one.component.css']
})
export class ManyToOneComponent implements OnInit {

  @Input() name!:String
  @Input() url!:String
  
  public values!:any
  public valToCreate!:any
  public isUpdate!:Boolean

  private dbService!:DbServiceService<any,Number>
  private closeResult!:String
  

  displayedColumns: string[] = ["numberOfCells", "operations"]
  
  constructor(private http:HttpClient,private  cookieService:CookieService,  private modalService:NgbModal) {
    
   }

  ngOnInit(): void {
    console.log(environment.server_host+this.url)
    console.log(this.cookieService)
    this.dbService  = new DbServiceService(this.http, environment.server_host+this.url, this.cookieService)
    this.valToCreate = {id:null, name:null}
    this.sycData()
  }

  sycData(){
    this.valToCreate = {id:null, name:null}
    this.dbService.getAll().subscribe(data=>{
      this.values = data
    })
  }
  
  update(){

    this.dbService.update(this.valToCreate).subscribe(data=>{
      alert(this.name + " updated successfully")
      this.sycData()
    })

  }

  add(){
    this.dbService.add(this.valToCreate).subscribe(data=>{
      alert(this.name + " created successfully")
      this.sycData()
    })

  }

  delete(id:Number){
    if(confirm("Are you sure you want to delete "+ this.name+" "+ id)){
      this.dbService.delete(id).subscribe(data=>{
        alert(this.name + " removed successfully")
        this.sycData()
      })
    }
  }

  open(content:any) {
    this.valToCreate = {id:null, name:null}
    this.isUpdate = false
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openToUpdate(content:any, val:any) {
    this.isUpdate = true
    this.valToCreate = val;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    this.valToCreate = {id:null, name:null}
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
