<div class="row">
    <div class="col-6" style="text-align: center;">
        <mat-form-field appearance="outline">
            <mat-label>Module Voltage</mat-label>
            <input matInput placeholder="voltage" [(ngModel)]="module.voltage">
            <mat-hint></mat-hint>
        </mat-form-field>
    </div>
    <div class="col-6" style="text-align: center;">
        <mat-form-field appearance="outline">
            <mat-label>ACIR</mat-label>
            <input matInput placeholder="acir" [(ngModel)]="module.acir">
            <mat-hint></mat-hint>
        </mat-form-field>
    </div>
    <div class="col-12"><h4 style="text-align: center;">Module Condition</h4></div>
    <div class="col-4 " *ngFor="let condition of packConditions" >
        <div [ngClass]="{'active-card': module.physicalConditionsIds.indexOf(condition.id) > -1, 'c-card': module.physicalConditionsIds.indexOf(condition.id) == -1}" (click)="setPackCondition(condition)" >{{condition.name}}</div>
    </div>
    <div class="col-12"><button (click)="save()"><span *ngIf="moduleId==-1">ADD</span><span *ngIf="moduleId!=-1">UPDATE</span></button></div>
</div>
