<div class="row">
    <div class="col-2">
      <a [routerLink]="['/test-details']" class="btn btn-info">Back</a>
    </div>
    <div class="col-10">
      <h2>Test Detail</h2>
    </div>
  </div>
  <div class="row" style="border-width: 2px;margin-top: 10px;" *ngFor="let testDetail of testDetails">
      <div class="col-3">Batch Id: </div>
      <div class="col-3"><input type="text" disabled placeholder="" value="{{testDetail['batchId']}}" style="width: 100%;"/></div>
      <div class="col-3">Name: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="testDetail['subunitNumber']" style="width: 100%;"/></div>
      
      
      <!-- <div class="col-3">Protocol Type: </div>

      <div class="col-3">C Rate: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="testDetail.channelNumber" style="width: 100%;"/></div>
      
      <div class="col-3">Start V: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="testDetail.objective" style="width: 100%;"/></div>
      <div class="col-3">UV: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="testDetail.cycleNumber" style="width: 100%;"/></div>
      
      <div class="col-3">LV: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="testDetail.date" style="width: 100%;"/></div>
      <div class="col-3">Pulse No: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="testDetail.positionNumber" style="width: 100%;"/></div>
      
      <div class="col-3">Pulse Position: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="testDetail.dacId" style="width: 100%;"/></div>
      <div class="col-3">Op Status: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="testDetail.temperature" style="width: 100%;"/></div>
      
      
      <div class="col-3">Developer Id: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="testDetail.developerId" style="width: 100%;"/></div>
      <div class="col-3">Steps No: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="testDetail.stepsNo" style="width: 100%;"/></div>
      
      <div class="col-6">Comments: </div>
      <div class="col-6"><input type="text" placeholder="" [(ngModel)]="testDetail.comments" style="width: 100%;"/></div> -->
      
      
      <!-- <div class="col-12">Json Data: </div>
      <div class="col-12" style="height:600px;"><json-editor #editor [options]="editorOptions" [data]="testDetail.jsonData"></json-editor>
      </div> -->
      
      <!-- <div class="col-12" style="text-align: center;"><button style="width: 50%;margin-top: 10px;" (click)="updateProtocol()">Update</button></div> -->
  </div>
  
  
  