<div class="row">
    <div class="col-2">
      <a [routerLink]="['/protocol']" class="btn btn-info">Back</a>
    </div>
    <div class="col-10">
      <h2>Protocol</h2>
    </div>
  </div>
  <div class="row" style="border-width: 2px;margin-top: 10px;">
      <div class="col-3">Id: </div>
      <div class="col-3"><input type="text" disabled placeholder="" value="{{protocol.protocolId}}" style="width: 100%;"/></div>
      <div class="col-3">Name: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="protocol.protocolName" style="width: 100%;"/></div>
      
      
      <div class="col-3">Protocol Type: </div>
      <div class="col-3">
        <select name="protocolType" id="protocolTypeId" class="form-control" [(ngModel)]="protocol.protocolTypeId">
            <option *ngFor="let type of protocolTypes" [value]="type.id">
                {{type.protocolTypeName}}
            </option>
        </select>
      </div>
      <div class="col-3">C Rate: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="protocol.cRate" style="width: 100%;"/></div>
      
      <div class="col-3">Start V: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="protocol.startV" style="width: 100%;"/></div>
      <div class="col-3">UV: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="protocol.uv" style="width: 100%;"/></div>
      
      <div class="col-3">LV: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="protocol.lv" style="width: 100%;"/></div>
      <div class="col-3">Pulse No: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="protocol.pulseNo" style="width: 100%;"/></div>
      
      <div class="col-3">Pulse Position: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="protocol.pulsePosition" style="width: 100%;"/></div>
      <div class="col-3">Op Status: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="protocol.opStatus" style="width: 100%;"/></div>
      
      
      <div class="col-3">Developer Id: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="protocol.developerId" style="width: 100%;"/></div>
      <div class="col-3">Steps No: </div>
      <div class="col-3"><input type="text" placeholder="" [(ngModel)]="protocol.stepsNo" style="width: 100%;"/></div>
      
      <div class="col-6">Comments: </div>
      <div class="col-6"><input type="text" placeholder="" [(ngModel)]="protocol.comments" style="width: 100%;"/></div>
      
      
      <div class="col-12">Json Data: </div>
      <div class="col-12" style="height:600px;"><json-editor #editor [options]="editorOptions" [data]="protocol.jsonData"></json-editor>
      </div>
      
      <div class="col-12" style="text-align: center;"><button style="width: 50%;margin-top: 10px;" (click)="updateProtocol()">Update</button></div>
  </div>
  
  
  