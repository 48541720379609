import { Component, OnInit, Input } from '@angular/core';
import { Strings } from 'src/app/models/strings';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DbServiceService } from '../../services/dbservice.service';
import { PhysicalCondition } from 'src/app/models/physical-condition';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-add-strings',
  templateUrl: './add-strings.component.html',
  styleUrls: ['./add-strings.component.css']
})
export class AddStringsComponent implements OnInit {

  public strings!:Strings
  public packConditions!:PhysicalCondition[]
  
  @Input() public stringId!:Number
  @Input() public parentId!:Number
  @Input() public parentType!:Number

  private conditionsService!:DbServiceService<PhysicalCondition,Number>
  private stringsService!:DbServiceService<Strings,Number>
  
  constructor(private http:HttpClient, private cookieService:CookieService,private route:ActivatedRoute, private router:Router) {
    this.packConditions = []
    this.conditionsService = new DbServiceService(this.http, environment.server_host+"/api/physical-conditions",this.cookieService)
    this.route.params.subscribe( params => this.stringId = params['id']);
   }

  ngOnInit(): void {
    this.strings = new Strings()
    this.syncData()
  }

  syncData(){
    this.conditionsService.getAll().subscribe(data=>{
      this.packConditions = data
      if(this.stringId==-1){
        this.strings = new Strings()
      }else{
        console.log(this.stringId)
        this.stringsService = new DbServiceService(this.http, environment.server_host+"/api/strings",this.cookieService)
        this.stringsService.get(this.stringId).subscribe(data=>{
          this.strings = data
        })
      }
    })
  }

  setPackCondition(cond:PhysicalCondition){
    var index = this.strings.physicalConditionsIds.indexOf(cond.id)
    if(index > -1){
      this.strings.physicalConditionsIds.splice(index,1)
    }else{
      this.strings.physicalConditionsIds.push(cond.id)
    }
  }

  save(){
    if(this.stringId==-1){
      if(this.parentType==1){
        this.strings.packId = this.parentId
      }else{
        this.strings.moduleId = this.parentId
      }
      this.stringsService = new DbServiceService(this.http, environment.server_host+"/api/strings",this.cookieService)
      this.stringsService.add(this.strings).subscribe(data=>{
       alert("String Added Successfully")
      }, error=>{
        alert("something went wrong")
      })
    }else{
      this.stringsService.update(this.strings).subscribe(data=>{
        alert("String Updated Successfully")
      }, error=>{
        alert("something went wrong")
      })
    }
  }

}
