import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public static userName:string
  isLogged:boolean
  title = 'Internal UI';
  constructor(private router:Router, private auth:AuthService, private cookieService:CookieService){
    this.router.navigateByUrl('/login');
    this.isLogged=false
  }
  ngOnInit(): void {
    
  }

  getUserName():string{
    return AppComponent.userName
  }


  public logout(){
    this.isLogged=false;
    this.cookieService.delete("loggedin-user");
    this.cookieService.delete("userName");
    this.router.navigateByUrl('/login');
    AppComponent.userName=''
  }
}
