import { PhysicalCondition } from './physical-condition'
import { Module } from './module'
import { Pack } from './pack'

export class Strings {
    id!:Number
    voltage!:Number
    acir!:Number
    physicalConditions:PhysicalCondition[] = []
    physicalConditionsIds:Number[] = []
    module!:Module
    moduleId!:Number
    pack!:Pack
    packId!:Number
}
