import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthToken } from './auth-token';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authUrl!:string
  private nunam_auth!:string
  constructor(private http: HttpClient, private cookieService:CookieService) { 
    this.authUrl =  environment.authUrl+"/api/auth"
  }

  public login(userName:string, password:string): Observable<AuthToken> {
    return this.http.post<AuthToken>(this.authUrl,{'userName':userName, 'password':password});
  }

   public getTbTenantToken(accessToken:string, tenantId:string):Observable<AuthToken>{
    const headerDict = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': accessToken 
    }
    const requestOptions = {                                                                                                                                                                                  
      headers: new HttpHeaders(headerDict)
    };
     return this.http.post<AuthToken>(this.nunam_auth+"/thingsboard/token", {'tenantId':tenantId},requestOptions);
   }
 
}
