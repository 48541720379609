<div class="row" style="background-color: rgb(221, 153, 153); border: solid 2px rgb(107, 106, 106);border-radius: 10px;width: 80%;margin-left: 10%;color: white;">
    <div class="col-3">
        ID: {{strings.id}}
    </div> 
    <div class="col-3">
        Voltage: {{strings.voltage}}
    </div> 
    <div class="col-3">
        ACIR: {{strings.acir}}
    </div>
    <div class="col-3">
        <mat-chip color="primary" (click)="open(mymodal)" selected>EDIT</mat-chip>
    </div> 
    <div class="col-12">
        Physical Condition: <mat-chip-list aria-label="condition">
            <mat-chip *ngFor="let condition of strings.physicalConditions">{{condition.name}}</mat-chip>
        </mat-chip-list>
    </div>
    <ng-template #mymodal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Update String</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <app-add-strings *ngIf="strings.packId!=null" [stringId]="strings.id" [parentId]="strings.packId" [parentType]="1"></app-add-strings>
            <app-add-strings *ngIf="strings.module!=null" [stringId]="strings.id" [parentId]="strings.moduleId" [parentType]="2"></app-add-strings>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="refresh()">close</button>
        </div>
    </ng-template>  
</div>