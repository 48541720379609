<div class="row">
    <div class="col-9">
        <h2>Test Details</h2>
    </div>
    <div class="col-3" style="text-align: left;">
        <!-- <button class="btn btn-lg btn-outline-primary" (click)="open(mymodal)">+</button> -->
    </div>
</div>
<div class="row">
    <div class="col-1"><b>Batch Id</b></div>
    <div class="col-3"><b>Number Of Record</b></div>
    <div class="col-1"><b>Date</b></div>
</div>
    
<div class="row" style="margin-top: 10px;margin-bottom: 10px;" *ngFor="let detail of testDetails">
    <div class="col-1" style="text-align: left;">{{detail.batchId}}</div>
    <div class="col-3"  style="text-align: left;">{{detail.numberOfRecord}}</div>
    <div class="col-4"  style="text-align: left;">{{detail.date}}</div>
    <div class="col-1"  style="text-align: left;"><a [routerLink]="['/test-detail-management', detail.batchId]" class="btn btn-info">=></a></div>
</div>
    
