import { Component, OnInit, ViewChild } from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import { Pack } from 'src/app/models/pack';
import { DbServiceService } from 'src/app/shared/services/dbservice.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { Cell } from 'src/app/models/cell';
import { ChildEntity } from 'src/app/models/child-entity';
import { PhysicalCondition } from 'src/app/models/physical-condition';


@Component({
  selector: 'app-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.css']
})
export class CellComponent implements OnInit {


  private packService!:DbServiceService<Pack,Number>
  private cellService!:DbServiceService<Cell,Number>
  
  public cells:any[] = []
  public totalCells!:Number
  public s!:Number
  public e!:Number
  public itemsPerPage:Number = 30
  public pagesPerDiaplay:Number = 10
  public totalNumberOfPages!:Number
  public currentPage!:Number
  public message!:String
  public filter:any = {

  }

  constructor(private http:HttpClient, private cookieService:CookieService, private modalService:NgbModal,private route:ActivatedRoute) {
    this.cellService = new DbServiceService(http, environment.server_host+"/api/cells", cookieService)
  }

  public cellTypes:ChildEntity[] = []
  public cellStages:ChildEntity[] = []
  public manufacturers:ChildEntity[] = []
  public chemistries:ChildEntity[] = []
  public cellModels:ChildEntity[] = []
  public eWastes:ChildEntity[] = []

  public packConditions:PhysicalCondition[] = []

  public manufacturer:Number=-1
  public cellType:Number=-1
  public cellStage:Number=-1
  public chemistry:Number=-1
  public cellModel:Number=-1
  public eWaste:Number=-1

  public cellToUpdate:any


  ngOnInit(): void {


    var msg = "{{((+currentPage-1)*+itemsPerPage)+1}} to {{((+currentPage-1)*+itemsPerPage)+itemsPerPage}}"
    this.filter = {}
    this.load(1)
    this.loadFilter()
    this.s = 1


    new DbServiceService<ChildEntity, Number>(this.http, environment.server_host+"/api/cell-types", this.cookieService).getAll().subscribe(data=>{
      this.cellTypes = data
    })

    new DbServiceService<ChildEntity, Number>(this.http, environment.server_host+"/api/manufacturers", this.cookieService).getAll().subscribe(data=>{
      this.manufacturers = data
    })

    new DbServiceService<ChildEntity, Number>(this.http, environment.server_host+"/api/chemistries", this.cookieService).getAll().subscribe(data=>{
      this.chemistries = data
    })

    new DbServiceService<ChildEntity, Number>(this.http, environment.server_host+"/api/e-wastes", this.cookieService).getAll().subscribe(data=>{
      this.eWastes = data
    })

    new DbServiceService<ChildEntity, Number>(this.http, environment.server_host+"/api/cell-models", this.cookieService).getAll().subscribe(data=>{
      this.cellModels = data
    })

    new DbServiceService<PhysicalCondition, Number>(this.http, environment.server_host+"/api/physical-conditions", this.cookieService).getAll().subscribe(data=>{
      this.packConditions = data
    })

    this.cellStages = [
      {id: 1, name: "Dismantled"},
      {id: 2, name: "In Test"},
      {id: 3, name: "To Be Grouped"},
      {id: 4, name: "Assembled"},
      {id: 11, name: "Rejected on Testing"},
    ]

  }

  public nextPages(){
    var c = +this.s + +this.pagesPerDiaplay
    if (c > this.totalNumberOfPages)
      return
    this.s = c
  }

  public prevPages(){
    if(this.s==1)
      return
    this.s = +this.s - +this.pagesPerDiaplay
  }

  public download(){
    if(this.totalCells==null){
      alert("please apply filter before downloading!")
    }
    this.message = "Loading..."
    new DbServiceService<any, Number>(this.http, environment.server_host+"/api/cells/filter?offset=0&limit="+(+this.totalCells+1)+"&orderBy=nunamId", this.cookieService).add(this.filter).subscribe(data=>{
      var content = this.getCSVString(data)
      if(content!=null){
        let blob = new Blob(['\ufeff' + content], { 
          type: 'text/csv;charset=utf-8;'
        }); 
        let dwldLink = document.createElement("a"); 
        let url = URL.createObjectURL(blob);
        //if Safari open in new window to save file with random filename. 
        dwldLink.setAttribute("href", url); 
        dwldLink.setAttribute("download", "cells" + ".csv"); 
        dwldLink.style.visibility = "hidden"; 
        document.body.appendChild(dwldLink); 
        dwldLink.click(); 
        document.body.removeChild(dwldLink); 
      }
      this.message = ""
    })
  }


  public load(pageNumber:any){
    this.cells = []
    this.message = "Loading..."
    new DbServiceService<any, Number>(this.http, environment.server_host+"/api/cells/filter?offset="+((+pageNumber-1)*+this.itemsPerPage)+"&limit="+this.itemsPerPage+"&orderBy=nunamId", this.cookieService).add(this.filter).subscribe(data=>{
      this.cells = data
      this.cells.push(new Cell())
      this.message = ""
      this.currentPage = pageNumber
    })
  }

  public setFilter(){
    this.filter = {}
    if(this.manufacturer!=-1){
      this.filter['manufacturer'] = +this.manufacturer
    }
    if(this.chemistry!=-1){
      this.filter['chemistry'] = +this.chemistry
    }
    if(this.cellStage!=-1){
      this.filter['cellStage'] = +this.cellStage
    }
    if(this.eWaste!=-1){
      this.filter['eWaste'] = +this.eWaste
    }
    this.loadFilter()
    this.load(1)
  }

  public loadFilter(){
    
    
    new DbServiceService<Number, Number>(this.http, environment.server_host+"/api/cells/filter/count", this.cookieService).add(this.filter).subscribe(data=>{
      this.totalCells = +data
      var totalPages = +this.totalCells/+this.itemsPerPage
      this.totalNumberOfPages = Number(totalPages.toFixed(0))
      if(+totalPages%1!=0){
        this.totalNumberOfPages  = +this.totalNumberOfPages + 1
      }
    })
  }

  private getCSVString(cells:any[]){
    if(cells.length>0){
      var str = ""
      var cols = ["nunamId","acir","cellId","cellVoltage","cellWeight","comments","nominalCapacity","nominalVoltage","nunamScore","time","width"]
      var subCols = ["chemistry","eWaste","manufacturer","cellStage","cellType"]
      str = "nunamId,acir,cellId,cellVoltage,cellWeight,comments,nominalCapacity,nominalVoltage,nunamScore,time,width,chemistry,eWaste,manufacturer,cellStage,cellType\r\n"
      cells.forEach(cell => {
        var row = ""  
        cols.forEach(element => {

          if(cell[element] != null){
            row += (cell[element]+", ")
          }else{
            row += ","
          }
        });

        subCols.forEach(element => {
          if(cell[element] != null){
            var val:any = cell[element]
            row+= (val.name+", ") 
          }else{
            row += ","
          }
        })
        row.substring(0, row.length-1)
        row+="\r\n"
        str+=row
      });
      return str
      }else{
        alert("no data to download")
        return null
      }
  }
  public closeResult!:String
  public open(content:any, cell:any) {
    this.cellToUpdate = cell
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = 
         `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public update(){
    var input = {
      nunamId:this.cellToUpdate.nunamId,
      cellId:this.cellToUpdate.cellId,
      cellWeight:this.cellToUpdate.cellWeight,
      acir:this.cellToUpdate.acir,
      nunamScore:this.cellToUpdate.nunamScore,
      nominalVoltage:this.cellToUpdate.nominalVoltage,
      cellVoltage:this.cellToUpdate.cellVoltage,
      comments:this.cellToUpdate.comments,
      width:this.cellToUpdate.width,
      nominalCapacity:this.cellToUpdate.nominalCapacity,
      cellTypeId:this.cellToUpdate.cellTypeId,
		  chemistryId:this.cellToUpdate.chemistryId,
		  manufacturerId:this.cellToUpdate.manufacturerId,
		  eWasteId:this.cellToUpdate.eWasteId
    }
    new DbServiceService<any,any>(this.http, environment.server_host+"/api/cells", this.cookieService).update(input).subscribe(data=>{
      alert("Cell Details Updated")
    }, error=>{
      alert("Something went wring!, please try again later")
    })
    
  }

  
 
}
