import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Protocol } from '../protocol';
import { RestService } from 'src/app/rest-service.service';
import { CookieService } from 'ngx-cookie-service';
import { ProtocolType } from '../protocol-type';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';


@Component({
  selector: 'app-protocol-management',
  templateUrl: './protocol-management.component.html',
  styleUrls: ['./protocol-management.component.css']
})
export class ProtocolManagementComponent implements OnInit {

  protocolId!:Number
  protocol!:Protocol
  protocolTypes!:ProtocolType[]
  editorOptions!:JsonEditorOptions
  @ViewChild(JsonEditorComponent, { static: false }) editor!: JsonEditorComponent;

  constructor( private route: ActivatedRoute, private restService:RestService<Protocol>, private cookieService:CookieService, private typeService:RestService<ProtocolType>) { 
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.mode = 'text'; // set all allowed modes
    this.route.params.subscribe( params => this.protocolId = params['id']);
    this.typeService.getAll("Bearer "+ this.cookieService.get("access-token"), "api/protocol-types").subscribe(types=>{
      this.protocolTypes = types
      this.restService.get("Bearer "+ this.cookieService.get("access-token"), "api/protocols/"+this.protocolId).subscribe(protocols=>{
        this.protocol = protocols
        console.log(this.protocol.jsonData)
      })
    })
    
  }

  ngOnInit(): void {

  }

  updateProtocol(){
    if(!this.editor.isValidJson){
      alert("invalid jsin data")
      return
    }
    this.protocol.jsonData = this.editor.get()
    this.restService.update("Bearer "+this.cookieService.get("access-tocken"), "api/protocols/", this.protocol).subscribe(data=>{
      this.protocol = data;
      alert("Protocol Updated")
    }, error=>{
      alert("something went wrong!")
    })
  }

  

}
