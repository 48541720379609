<!-- <mat-accordion *ngFor="let i of [].constructor(numberOfCells), let x = index"> -->
<div class="main">
  <h2 style="text-align: center;">Tests</h2>

  <div class="row menu-section" style="width: 80%;padding: 15px;margin-left: 10%;background-color: rgb(192, 211, 247);">

    <div class="col-3">
        <label>Test In Pogress: </label>
    </div>
    <div class="col-3">
        <b>{{inProgress}}</b>
    </div>
    <div class="col-3">
        <label>Test Completed: </label>
    </div>
    <div class="col-3">
        <b>{{completed}}</b>
    </div>
    <div class="col-3">
        <label>In Uploaded: </label>
    </div>
    <div class="col-3">
        <b>{{inUpload}}</b>
    </div>
    <div class="col-3">
        <label>Test Processed: </label>
    </div>
    <div class="col-3">
        <b>{{processed}}</b>
    </div>
  
    <div class="col-6">
      <label>Protocol</label>
    </div>
    <div class="col-6">
      <select [(ngModel)]="protocol">
        <option value="-1">-Select-</option>
        <option *ngFor="let p of protocols" value="{{p.id}}">
          {{p.name}}-{{p.id}}
        </option>
      </select>
    </div>

    <div class="col-3">
      <label>Status</label>
    </div>
    <div class="col-3">
      <select [(ngModel)]="status">
        <option value="-1">-Select-</option>
        <option value="1">Configured</option>
        <option value="2">Running</option>
        <option value="3">Finished</option>
        <option value="4">Test Synced</option>
        <option value="5">Data Uploaded</option>
        <option value="6">Data Stored</option>
        <option value="7">CSVs generated</option>
        <option value="8">ICA Calculated</option>
        <option value="9">Metrics Calculated</option>
      </select>
    </div>

    <div class="col-3">
      <label>Batch Id</label>
    </div>
    <div class="col-3">
      <input type="text" [(ngModel)]="batchId"/>
    </div>

    <div class="col-3">
      <label>Nunam Id</label>
    </div>
    <div class="col-3">
      <input type="text" [(ngModel)]="nunamId"/>
    </div>

    <div class="col-12" style="text-align: center;">
      <div class="button" (click)="setFilter()">Apply</div>
    </div>
  </div>
  
  <div class="row stats" style="width: 100%;padding: 50px;">
    
    <div class="col-12">
      <div class="row">
        <div class="col-12"><h4 style="text-align: center;">{{message}}</h4></div>
        <div class="col-3"><h4 style="text-align: center;">Page Number: {{currentPage}}</h4></div>
        <div class="col-3"><h4 style="text-align: center;">Total Tests: {{totalTests}}</h4></div>
        <div class="col-3"><h4 style="text-align: center;">showing: </h4></div>
        <!-- <div class="col-3"><div style="float:right" class="button" (click)="download()">Download</div></div> -->
      </div>
    </div>
  </div>
  
  <div class="row data"  style="width: 80%;padding: 15px;margin-left: 10%;background-color: rgb(192, 211, 247);">
    <div class="col-12 table" >
      <div class="row" style="background-color: cornflowerblue;">
        <div class="col-2">Batch Id</div>
        <div class="col-4">Protocol</div>
        <div class="col-2">Number Of cycles</div>
        <div class="col-2">Rate</div>
        <div class="col-2">Status</div>
      </div>
      <div class="row data-row" *ngFor="let test of tests; let i = index" [ngClass]="{'even-row': i%2==0, 'odd-row': i%2==1}" (click)=rowClick(i,test.id)>
        <div class="col-2">{{test.id}}</div>
        <div class="col-4">{{test.protocolId}}</div>
        <div class="col-2">{{test.numberOfCycles}}</div>
        <div class="col-2">{{test.chargeCRate}}</div>
        <div class="col-2">
          <div *ngIf="test.status==1">
            <span>Configured</span>
            <button (click)="startTest(test.id)" class="start-btn">Start Test</button>
          </div>
          <div *ngIf="test.status==2">
            <span>Running</span>
            <button (click)="stopTest(test.id)" class="stop-btn">Stop Test</button>
          </div>
          <div *ngIf="test.status>2">
            {{testStaus[test.status]}}
          </div>
        </div>
        <div class="col-12" *ngIf=rowExpand[i] style="background-color: darkgray;">
          <div class="col-12 table" >
            <b style="text-align: center;" *ngIf="rowData[test.id]==null">Loading...</b>
            <div class="row" style="background-color: rgb(241, 163, 163);">
              <div class="col-2">Nunam Id</div>
              <div class="col-2">Equipment</div>
              <div class="col-1">Channel</div>
              <div class="col-1">Number of records</div>
              <div class="col-2">Status</div>
              <div class="col-2">Download</div>
              <div class="col-2">Download</div>
            </div>
            <div class="row" *ngFor="let link of rowData[test.id]; let i = index" [ngClass]="{'even-row-sub': i%2==0, 'odd-row-sub': i%2==1}">
              <div class="col-2">{{link.cellId}}</div>
              <div class="col-2">{{link.unitId}}</div>
              <div class="col-1">{{link.channelId}}</div>
              <div class="col-1">{{link.numberOfRecords}}</div>
              <div class="col-2">{{testStaus[link.status]}}</div>
              <div class="col-2">
                <div *ngIf="link.status>7 && link.status<10" style="float:right" class="button" (click)="downloadICA(link.cellTestLinkId)">ICA</div>
              </div>
              <div class="col-2">
                <div *ngIf="link.status>8 && link.status<10" style="float:right" class="button" (click)="downloadMetrics(link.cellTestLinkId)">Metrics</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="pagination row">
    <ul>
      <li (click)="prevPages()"><a><-PREV</a></li>
      <li *ngFor="let item of [].constructor(pagesPerDiaplay); let i = index" (click)="load((+i)+(+s))">
        <a>{{(+i)+(+s)}}</a>
      </li>
      <li (click)="nextPages()"><a>NEXT-></a></li>
    </ul>
    <br/>
  </div>
  <h5 style="text-align: center;">Page {{s}} to {{(+s)+(+pagesPerDiaplay)-1}} of {{totalNumberOfPages}}</h5>
</div>
      

