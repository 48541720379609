<!-- <mat-accordion *ngFor="let i of [].constructor(numberOfCells), let x = index"> -->
<div class="main">
  <h2 style="text-align: center;">Cells</h2>
  <div class="row" style="width: 80%;padding: 15px;margin-left: 10%;background-color: rgb(192, 211, 247);">
    <div class="col-3">
      <label>Manufacturer</label>
    </div>
    <div class="col-3">
      <select [(ngModel)]="manufacturer">
        <option value="-1">-Select-</option>
        <option *ngFor="let m of manufacturers" value="{{m.id}}">
          {{m.name}}
        </option>
      </select>
    </div>

    <div class="col-3">
      <label>Chemistries</label>
    </div>
    <div class="col-3">
      <select [(ngModel)]="chemistry">
        <option value="-1">-Select-</option>
        <option *ngFor="let c of chemistries" value="{{c.id}}">
          {{c.name}}
        </option>
      </select>
    </div>

    <div class="col-3">
      <label>EWaste Source</label>
    </div>
    <div class="col-3">
      <select [(ngModel)]="eWaste">
        <option value="-1">-Select-</option>
        <option *ngFor="let e of eWastes" value="{{e.id}}">
          {{e.name}}
        </option>
      </select>
    </div>

    <div class="col-3">
      <label>Cell Stage</label>
    </div>
    <div class="col-3">
      <select [(ngModel)]="cellStage">
        <option value="-1">-Select-</option>
        <option *ngFor="let c of cellStages" value="{{c.id}}">
          {{c.name}}
        </option>
      </select>
    </div>

    <div class="col-12" style="text-align: center;">
      <div class="button" (click)="setFilter()">Apply</div>
    </div>

    

  </div>
  
  <div class="row" style="width: 100%;padding: 50px;">
    <div class="col-12">
      <div class="row">
        <div class="col-12"><h4 style="text-align: center;">{{message}}</h4></div>
        <div class="col-3"><h4 style="text-align: center;">Page Number: {{currentPage}}</h4></div>
        <div class="col-3"><h4 style="text-align: center;">Total Cells: {{totalCells}}</h4></div>
        <div class="col-3"><h4 style="text-align: center;">showing: </h4></div>
        <div class="col-3"><div style="float:right" class="button" (click)="download()">Download</div></div>
      </div>
    </div>
    <div class="col-12">
        <table>
            <tr>
                <th>Nunam Id</th>
                <th>Cell Id</th>
                <th>Nominal Voltage</th>
                <th>Nominal Capacity</th>
                <th>Cell Voltage</th>
                <th>Cell Weight</th>
                <th>Cell Width (mm)</th>
                <th>Manufacturer</th>
                <th>chemistry</th>
                <th>Comments</th>
            </tr>
            <tr *ngFor="let cell of cells" (click)="open(content, cell)">
                <td>{{cell.nunamId}}</td>
                <td>{{cell.cellId}}</td>
                <td>{{cell.nominalVoltage}}</td>
                <td>{{cell.nominalCapacity}}</td>
                <td>{{cell.cellVoltage}}</td>
                <td>{{cell.cellWeight}}</td>
                <td>{{cell.width}}</td>
                <td>{{cell.manufacturer!=null?cell.manufacturer.name:''}}</td>
                <td>{{cell.chemistry!=null?cell.chemistry.name:''}}</td>
                <td>{{cell.comments}}</td>
            </tr>
        </table>
        <div class="pagination">
          <ul>
            <li (click)="prevPages()"><a><-PREV</a></li>
            <li *ngFor="let item of [].constructor(pagesPerDiaplay); let i = index" (click)="load((+i)+(+s))">
              <a>{{(+i)+(+s)}}</a>
            </li>
            <li (click)="nextPages()"><a>NEXT-></a></li>
          </ul>
          <br/>
          
        </div>
        <h5 style="text-align: center;">Page {{s}} to {{(+s)+(+pagesPerDiaplay)-1}} of {{totalNumberOfPages}}</h5>
    </div>
  </div>

  <ng-template #content let-modal>
    <div style="text-align: center;"><b style="text-align: center;">Update Cell</b></div>
    <div class="row">
      <div class="col-12">
        <form>
          <label for="nunamId">Nunam ID:</label>
          <input type="text" id="nunamId" name="nunamId" [(ngModel)]="cellToUpdate.nunamId"><br>
      
          <label for="acir">ACIR:</label>
          <input type="text" id="acir" name="acir" [(ngModel)]="cellToUpdate.acir"><br>
      
          <label for="cellId">Cell ID:</label>
          <input type="text" id="cellId" name="cellId" [(ngModel)]="cellToUpdate.cellId"><br>
      
          <label for="cellVoltage">Cell Voltage:</label>
          <input type="text" id="cellVoltage" name="cellVoltage" [(ngModel)]="cellToUpdate.cellVoltage"><br>
      
          <label for="cellWeight">Cell Weight:</label>
          <input type="text" id="cellWeight" name="cellWeight" [(ngModel)]="cellToUpdate.cellWeight"><br>
      
          <label for="comments">Comments:</label>
          <textarea id="comments" name="comments" [(ngModel)]="cellToUpdate.comments"></textarea><br>
      
          <label for="nominalCapacity">Nominal Capacity:</label>
          <input type="text" id="nominalCapacity" name="nominalCapacity" [(ngModel)]="cellToUpdate.nominalCapacity"><br>
      
          <label for="nominalVoltage">Nominal Voltage:</label>
          <input type="text" id="nominalVoltage" name="nominalVoltage" [(ngModel)]="cellToUpdate.nominalVoltage"><br>
      
          <label for="nunamScore">Nunam Score:</label>
          <input type="text" id="nunamScore" name="nunamScore" [(ngModel)]="cellToUpdate.nunamScore"><br>
      
          <label for="width">Width:</label>
          <input type="text" id="width" name="width" [(ngModel)]="cellToUpdate.width"><br>
      
          <label for="chemistry">Chemistry:</label>
          <select id="chemistry" name="chemistry" [(ngModel)]="cellToUpdate.chemistryId">
            <option *ngFor="let c of chemistries" value="{{c.id}}">
              {{c.name}}
            </option>
          </select>
          <br>
      
          <label for="eWaste">eWaste:</label>
          <select id="eWaste" name="eWaste" [(ngModel)]="cellToUpdate.eWasteId">
            <option *ngFor="let c of eWastes" value="{{c.id}}">
              {{c.name}}
            </option>
          </select>
          <br>
      
          <label for="manufacturer">Manufacturer:</label>
          <select id="manufacturer" name="manufacturer" [(ngModel)]="cellToUpdate.manufacturerId">
            <option *ngFor="let c of manufacturers" value="{{c.id}}">
              {{c.name}}
            </option>
          </select>
          <br>
      
          <label for="cellType">Cell Type:</label>
          <select id="cellType" name="cellType" [(ngModel)]="cellToUpdate.cellTypeId">
            <option *ngFor="let c of cellTypes" value="{{c.id}}">
              {{c.name}}
            </option>
          </select>
          <br>
      </form>
      <div class="button" (click)="update()" style="width: 90%; margin-left: 5%;">Update Cell</div>
      </div>
    </div>
    
    <br>
  </ng-template>
</div>
