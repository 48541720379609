<div class="main">
    <h2 style="text-align: center;">Pack</h2>
    <div class="row" style="padding: 5px;">
        <!-- <div class="col-6"><Button style="float: left;" (click)=prev()>Back</Button></div>
        <div class="col-6"><Button style="float: right;" (click)=next()>Next</Button></div> -->
        <!-- <div class="col-12">
            <span *ngIf="pack.eWasteId!=null"><b>Source:</b> {{getEwasteName(pack.eWaste.id)}}</span>&nbsp;&nbsp;
            <span *ngIf="pack.source!=null"><b>Battery Type:</b> {{pack.source}}</span>&nbsp;&nbsp;
            <span *ngIf="pack.source == 'Laptop' && pack.laptopBrand!=null"><b>Laptop Brand:</b> {{pack.laptopBrand}}</span>&nbsp;&nbsp;
            <span *ngIf="pack.packCondition!=null"><b>Pack Condition:</b> {{pack.packCondition}}</span>&nbsp;&nbsp;
            <span *ngIf="pack.nominalVoltage!=null"><b>Nominal Voltage:</b> {{pack.nominalVoltage}}</span>&nbsp;&nbsp;
        </div> -->
    </div>
    <div class="row" style="padding: 5px;">
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>Pack ID</mat-label>
                <input matInput placeholder="pack ID" [(ngModel)]="pack.id" disabled>
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>
        <div class="col-12"><h4 style="text-align: center;">Choose E-Waste Source</h4></div>
        <div class="col-sm-12 col-md-6 col-lg-3" *ngFor="let source of eWasteSources" >
            <div [ngClass]="{'active-card': pack.eWasteId === source.id, 'c-card': pack.eWasteId != source.id}"  (click)=setEwasteSource(source.id)>{{source.name}}</div>
        </div>
    </div>
    <div class="row" style="padding: 5px;" >
        <div class="col-12"><h4 style="text-align: center;">Battery Type</h4></div>
        <div class="col-sm-12 col-md-6 col-lg-3 " *ngFor="let type of batteryTypes">
            <div [ngClass]="{'active-card': pack.source === type, 'c-card': pack.source != type}" (click)="setBatteryType(type)">{{type}}</div>
        </div>
        <div class="col-12" *ngIf="pack.source=='Laptop'">
            <div class="row">
                <div class="col-12"><h4 style="text-align: center;">Laptop Brand</h4></div>
                <div class="col-2 col-md-6 col-sm-12 " *ngFor="let brand of laptopBrand">
                    <div [ngClass]="{'active-card': pack.laptopBrand === brand, 'c-card': pack.laptopBrand != brand}" (click)=setBatteryBrand(brand)>{{brand}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="padding: 5px;" >
        <div class="col-12"><h4 style="text-align: center;">Pack Condition</h4></div>
        <div class="col-sm-12 col-md-6 col-lg-3 " *ngFor="let condition of packConditions" >
            <div [ngClass]="{'active-card': pack.physicalConditionsIds.indexOf(condition.id) > -1, 'c-card': pack.physicalConditionsIds.indexOf(condition.id) == -1}" (click)="setPackCondition(condition)" >{{condition.name}}</div>
        </div>
    </div>
    <div class="row" style="padding: 5px;">
        <!-- <div class="col-12"><h4 style="text-align: center;">Nominal Voltage</h4></div>
        <div class="col-3 " *ngFor="let voltage of nominalVoltages" >
            <div [ngClass]="{'active-card': pack.nominalVoltage === voltage, 'c-card': pack.nominalVoltage != voltage}" (click)="setNominalVoltage(voltage)" >{{voltage}}</div>
        </div> -->
        <div class="col-sm-12 col-md-6 col-lg-3 input">
            <mat-form-field appearance="outline">
                <mat-label>Nominal Voltage*</mat-label>
                <input matInput placeholder="voltage" [(ngModel)]="pack.nominalVoltage">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 input">
            <mat-form-field appearance="outline">
                <mat-label>Pack Capacity</mat-label>
                <input matInput placeholder="voltage" [(ngModel)]="pack.capacity">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 input">
            <mat-form-field appearance="outline">
                <mat-label>Output WH</mat-label>
                <input matInput placeholder="voltage" [(ngModel)]="pack.outputWh">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-3 input">
            <mat-form-field appearance="outline">
                <mat-label>Year Of Manufacturing</mat-label>
                <input matInput placeholder="voltage" [(ngModel)]="pack.manufacturingYear">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>
        <!-- <div class="col-3"></div>
        <div class="col-3">
           
            <select [(ngModel)]="pack.">
                <option>2007</option>
                <option>2008</option>
                <option>2009</option>
                <option>2010</option>
                <option>2011</option>
                <option>2012</option>
                <option>2013</option>
                <option>2014</option>
                <option>2015</option>
                <option>2016</option>
                <option>2017</option>
                <option>2018</option>
                <option>2019</option>
                <option>2020</option>
                <option>2021</option>
            </select>
        </div> -->

        <div class="col-sm-12 col-md-6 col-lg-3 input">
            <mat-form-field appearance="outline">
                <mat-label>Month Of Manufacturing</mat-label>
                <input matInput placeholder="voltage" [(ngModel)]="pack.manufacturingMonth">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>
        <!-- <div class="col-3"></div>
        <div class="col-3">
            
            <select [(ngModel)]="pack.manufacturingMonth">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
            </select>
        </div> -->

        <div class="col-sm-12 col-md-6 col-lg-3 input">
            <mat-form-field appearance="outline">
                <mat-label>Comments</mat-label>
                <input matInput placeholder="voltage" [(ngModel)]="pack.comments">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-3 input">
            <mat-form-field appearance="outline">
                <mat-label>Current Voltage</mat-label>
                <input matInput placeholder="voltage" [(ngModel)]="pack.currentVoltage">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-3 input">
            <mat-form-field appearance="outline">
                <mat-label>Weight</mat-label>
                <input matInput placeholder="voltage" [(ngModel)]="pack.weight">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-3 input">
            <mat-form-field appearance="outline">
                <mat-label>Nominal Energy</mat-label>
                <input matInput placeholder="voltage" [(ngModel)]="pack.nominalEnergy">
                <mat-hint></mat-hint>
            </mat-form-field>
        </div>

        <div class="col-12" style="text-align: center;"><br>BMS Data</div>
        <div class="col-12"><textarea rows="15" style="width: 80%;margin-left: 10%;" [(ngModel)]="pack.bmsData"></textarea></div>
        
        <div class="col-12"><Button (click)="updatePck()">Update</Button></div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="row" style="text-align: center;"> 
                <div class="col-sm-12 col-md-6 col-lg-6"><div [ngClass]="{'active-card': isModuled, 'c-card': !isModuled}"><h2 style="text-align: center;" (click)="isModuled=true;"><b>Modules</b></h2></div></div>
                <div class="col-sm-12 col-md-6 col-lg-6"><div [ngClass]="{'active-card': !isModuled, 'c-card': isModuled}"><h2 style="text-align: center;" (click)="isModuled=false;"><b>Strings</b></h2></div></div>
            </div>
            <div class="row" *ngIf="!isModuled">
                <div class="col-12">
                    <app-string *ngFor="let string of strings" [stringId]="string.id"></app-string>
                    <div style="text-align: center;">
                        <button (click)="open(mymodal)"  mat-mini-fab color="accent" aria-label="Add String">
                            S+
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isModuled">
                <div class="col-12">
                    <app-module *ngFor="let module of modules" [moduleId]="module.id"></app-module>
                    <div style="text-align: center;">
                        <button (click)="open(mymodal)"  mat-mini-fab color="accent" aria-label="Add String">
                            M+
                        </button>
                    </div>
                </div>
            </div>
            
            <ng-template #mymodal let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Add</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <app-add-strings *ngIf="!isModuled" [stringId]="-1" [parentType]="1" [parentId]="pack.id"></app-add-strings>
                    <app-add-module *ngIf="isModuled" [moduleId]="-1" [parentId]="pack.id"></app-add-module>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="closeModal()">close</button>
                </div>
            </ng-template> 
        </div>
    </div>
</div>
