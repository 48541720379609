import { Component, OnInit } from '@angular/core';
import { NunamBms } from './nunam-bms';
import { CookieService } from 'ngx-cookie-service';
import { RestService } from '../rest-service.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-nunam-bms',
  templateUrl: './nunam-bms.component.html',
  styleUrls: ['./nunam-bms.component.css']
})
export class NunamBmsComponent implements OnInit {

  closeResult!:String
  constructor(private cookieService:CookieService, private bmsService:RestService<NunamBms>, private modalService:NgbModal) { 
    this.newBms = new NunamBms()
    this.refresh()
  }
  nunamBmses!:NunamBms[]
  newBms!:NunamBms

  ngOnInit(): void {
  }

  open(content:any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  refresh(){
    this.newBms = new NunamBms()
    this.bmsService.getAll("Bearer "+this.cookieService.get("access-token"), "api/nunam-bms").subscribe(bmses=>{
      this.nunamBmses = bmses
    })
  }
  add(){
    this.bmsService.add("Bearer "+this.cookieService.get("access-token"), "api/nunam-bms", this.newBms).subscribe(bms=>{
      alert("bms added successfully")
      this.refresh()
    }, error=>{
      alert("somenthing went wrong")
    })
  }

  delete(id:Number){
    if(!confirm("Are you sure you want to delete bms"+ id)){
      return
    }
    this.bmsService.delete("Bearer "+this.cookieService.get("access-token"), "api/nunam-bms/"+id).subscribe(data=>{
      alert("bms removed successfully")
      this.refresh()
    }, error=>{
      alert("somenthing went wrong")
    })
  }

  update(id:Number){
    for(var i=0;i<this.nunamBmses.length;i++){
      if(this.nunamBmses[i].id == id){
        if(this.nunamBmses[i]!=null && this.nunamBmses[i].name!=""){
          this.bmsService.update("Bearer "+this.cookieService.get("access-token"), "api/nunam-bms", this.nunamBmses[i]).subscribe(data=>{
            alert("bms updated")
            this.refresh()
          })
        }else{
          alert("name cannot be null")
        }
      }
    }
  }

}
