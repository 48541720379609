export class NunamPack {
    id!:Number
    createdDate!:Date
    typeId!:Number
    type!:String
    statusId!:Number
    status!:String
    bmsId!:Number
    bmsName!:String
}
