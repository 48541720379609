import { Component, OnInit } from '@angular/core';
import { NunamBms } from '../nunam-bms/nunam-bms';
import { PackType } from './pack-type';
import { PackStatus } from './pack-status';
import { NunamPack } from './nunam-pack';
import { CookieService } from 'ngx-cookie-service';
import { RestService } from '../rest-service.service';
import { forkJoin } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-nunam-pack',
  templateUrl: './nunam-pack.component.html',
  styleUrls: ['./nunam-pack.component.css']
})
export class NunamPackComponent implements OnInit {

  bmses!:NunamBms[]
  packTypes!:PackType[]
  packStatus!:PackStatus[]
  nunamPacks!:NunamPack[]
  newPack!:NunamPack
  closeResult!:String
  constructor(private cookieService:CookieService, private packService:RestService<NunamPack>, private packStatusService:RestService<PackStatus>,
     private packTypeService:RestService<PackType>, private bmsService:RestService<NunamBms>, private modalService:NgbModal) { 
    this.newPack = new NunamPack()
    this.refresh()
  }

  ngOnInit(): void {
  }

  refresh(){
    this.newPack = new NunamPack()
    var typesCall = this.packTypeService.getAll("Bearer "+this.cookieService.get("access-token"), "api/pack-types");
    var statusesCall = this.packStatusService.getAll("Bearer "+this.cookieService.get("access-token"), "api/pack-statuses");
    var bmsesCall = this.bmsService.getAll("Bearer "+this.cookieService.get("access-token"), "api/nunam-bms");
    var packsCall = this.packService.getAll("Bearer "+this.cookieService.get("access-token"), "api/nunam-packs");
    forkJoin([typesCall, statusesCall, bmsesCall, packsCall]).subscribe(data=>{
      this.packTypes = data[0]
      this.packStatus = data[1]
      this.bmses = data[2]
      this.nunamPacks = data[3]
    })
  }

  open(content:any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  add(){
    this.newPack.createdDate = new Date()
    this.packService.add("Bearer "+this.cookieService.get("access-token"), "api/nunam-packs", this.newPack).subscribe(data=>{
      alert("pack added successfully");
      this.refresh();
    }, error=>{
      alert("something went wrong");
    })
  }
  delete(id:Number){
    if(!confirm("Are you sure, to delete Nunam Pack "+ id)){
      return 
    }
    this.packService.delete("Bearer "+this.cookieService.get("access-token"), "api/nunam-packs/"+id).subscribe(data=>{
      alert("pack deleted successfully");
      this.refresh();
    }, error=>{
      alert("something went wrong");
    })
  }

}