<div class="row" style="background-color: grey; border: solid 2px rgb(107, 106, 106);border-radius: 10px;width: 80%;margin-left: 10%;color: white;">
    <div class="col-3">
        ID: {{module.id}}
    </div> 
    <div class="col-3">
        Voltage: {{module.voltage}}
    </div> 
    <div class="col-3">
        ACIR: {{module.acir}}
    </div>
    <div class="col-3">
        <mat-chip color="primary" (click)="open(mymodal)" selected>EDIT</mat-chip>
    </div> 
    <div class="col-12">
        Physical Condition: <mat-chip-list aria-label="condition">
            <mat-chip *ngFor="let condition of module.physicalConditions">{{condition.name}}</mat-chip>
        </mat-chip-list>
    </div>
    <div class="col-12">
        <h2 style="text-align: center;">Strings</h2>
        <app-string style="background-color: rgb(255, 194, 194);" *ngFor="let strs of strings" [stringId]="strs.id"></app-string>
        <div style="text-align: center;">
            <button (click)="open(mymodal2)"  mat-mini-fab color="accent" aria-label="Add String">
                S+
            </button>
            
        </div>
        <ng-template #mymodal2 let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Add String</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <app-add-strings [stringId]="-1" [parentType]="2" [parentId]="module.id"></app-add-strings>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="closeModal()">close</button>
            </div>
        </ng-template> 
    </div>
    <ng-template #mymodal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Update Module</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <!-- <app-add-module [moduleId]="module.id"  [parentType]="1"></app-add-module> -->
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="refresh()">close</button>
        </div>
    </ng-template>  
</div>