import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { ProtocolComponent } from './protocol/protocol.component';
import { ProtocolManagementComponent } from './protocol/protocol-management/protocol-management.component';
import { NunamBmsComponent } from './nunam-bms/nunam-bms.component';
import { NunamPackComponent } from './nunam-pack/nunam-pack.component';
import { TestDetailComponent } from './test-detail/test-detail/test-detail.component';
import { TestDetailManagementComponent } from './test-detail/test-detail-management/test-detail-management.component';
import { DismantlingComponent } from './dismantling/dismantling.component';
import { CellComponent } from './cell/cell/cell.component';
import { PackComponent } from './pack/pack.component';
import { SettingsComponent } from './settings/settings.component';
import { PackDetailsComponent } from './pack/pack-details/pack-details.component';
import { AddCellComponent } from './cell/add-cell/add-cell.component';
import { ConductTestComponent } from './testing-components/conduct-test/conduct-test.component';
import { TestExplorerComponent } from './testing-components/test-explorer/test-explorer.component';
import { NewareTestsComponent } from './testing-components/neware-tests/neware-tests.component';

const routes: Routes = [
  {path:'login', component: AuthComponent},
  {path:'protocol', component: ProtocolComponent},
  {path:'protocol-management/:id', component:ProtocolManagementComponent},
  {path:'nunam-bms', component: NunamBmsComponent},
  {path:'nunam-pack', component: NunamPackComponent},
  {path:'test-details', component:TestDetailComponent},
  {path:'test-detail-management/:id', component:TestDetailManagementComponent},
  {path:'dismantling', component:DismantlingComponent},
  {path:'cells', component:CellComponent},
  {path:'pack', component:PackComponent},
  {path:'add-cells', component:AddCellComponent},
  {path:'pack-details/:id', component:PackDetailsComponent},
  {path:'settings', component:SettingsComponent},
  {path:'start-test', component:ConductTestComponent},
  {path: 'neware-tests', component:NewareTestsComponent},
  {path:'explore-tests', component:TestExplorerComponent},
  {path: '',   redirectTo: '/first-login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
