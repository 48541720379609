<div class="parent">
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="outline">
                <mat-label>Pack ID</mat-label>
                <input matInput placeholder="pack ID" [(ngModel)]="searchId">
                <mat-hint></mat-hint>
            </mat-form-field>
            <a [routerLink]="['/pack-details', searchId]" class="btn btn-info">SEARCH</a>
        </div>
    </div>
    <table mat-table [dataSource]="packs">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Pack ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="numberOfCells">
            <th mat-header-cell *matHeaderCellDef> Number Of Cells </th>
            <td mat-cell *matCellDef="let element"> {{element.numberOfCells}} </td>
        </ng-container>

        <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef> Created At </th>
            <td mat-cell *matCellDef="let element"> {{element.time}} </td>
        </ng-container>

        <ng-container matColumnDef="eWaste.name">
            <th mat-header-cell *matHeaderCellDef> Source </th>
            <td mat-cell *matCellDef="let element"> <span *ngIf="element.eWaste">{{element.eWaste.name}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="operation">
            <th mat-header-cell *matHeaderCellDef> Operations </th>
            <td mat-cell *matCellDef="let element"> <a [routerLink]="['/pack-details', element.id]" class="btn btn-info">Manage Pack</a> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>