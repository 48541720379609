import { Component, OnInit } from '@angular/core';
import { Cell } from '../../models/cell';
import { ChildEntity } from 'src/app/models/child-entity';
import { PhysicalCondition } from 'src/app/models/physical-condition';
import { DbServiceService } from 'src/app/shared/services/dbservice.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NunamBms } from 'src/app/nunam-bms/nunam-bms';
import { isFormattedError } from '@angular/compiler';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-add-cell',
  templateUrl: './add-cell.component.html',
  styleUrls: ['./add-cell.component.css']
})
export class AddCellComponent implements OnInit {

  constructor(private http:HttpClient, private cookieService:CookieService) { }

  public newCell!:Cell
  public recentCells:Cell[] = []
  public lastNunamId!:Number

  public cellTypes:ChildEntity[] = []
  public cellStages:ChildEntity[] = []
  public manufacturers:ChildEntity[] = []
  public chemistries:ChildEntity[] = []
  public cellModels:ChildEntity[] = []
  public eWastes:ChildEntity[] = []

  public packConditions:PhysicalCondition[] = []

  public isSingleAdd:boolean = true


  

  
  public totalCount!:Number

  ngOnInit(): void {
    new DbServiceService<ChildEntity, Number>(this.http, environment.server_host+"/api/cell-types", this.cookieService).getAll().subscribe(data=>{
      this.cellTypes = data
    })

    new DbServiceService<ChildEntity, Number>(this.http, environment.server_host+"/api/manufacturers", this.cookieService).getAll().subscribe(data=>{
      this.manufacturers = data
    })

    new DbServiceService<ChildEntity, Number>(this.http, environment.server_host+"/api/chemistries", this.cookieService).getAll().subscribe(data=>{
      this.chemistries = data
    })

    new DbServiceService<ChildEntity, Number>(this.http, environment.server_host+"/api/e-wastes", this.cookieService).getAll().subscribe(data=>{
      this.eWastes = data
    })

    new DbServiceService<ChildEntity, Number>(this.http, environment.server_host+"/api/cell-models", this.cookieService).getAll().subscribe(data=>{
      this.cellModels = data
    })

    new DbServiceService<PhysicalCondition, Number>(this.http, environment.server_host+"/api/physical-conditions", this.cookieService).getAll().subscribe(data=>{
      this.packConditions = data
    })

   

    this.updateStats()
    this.newCell = new Cell()
    this.setCalbDefault()
    

  }

  public setCalbDefault(){
    this.newCell.nominalVoltage = 3.2
    this.newCell.cellTypeId = 2
    this.newCell.chemistryId = 1
    this.newCell.manufacturerId = 1
    this.newCell.cellModelId = 1
    this.newCell.eWasteId = 1
    this.newCell.nominalCapacity = 72
    this.newCell.physicalConditionsIds = [7]
  }

  
  updateStats(){
    new DbServiceService<Number, Number>(this.http, environment.server_host+"/api/cells/count", this.cookieService).getWithoutId().subscribe(data=>{
      this.totalCount = data
    })

    new DbServiceService<any, Number>(this.http, environment.server_host+"/api/cells/filter?offset=0&limit=1&orderBy=nunamId", this.cookieService).add({}).subscribe(data=>{
      if(data.length>0){
        this.lastNunamId = data[0].nunamId
      }
    })
  }

  public add(){
    var error = this.validate()
    if(error.length>0){
      alert(error)
      return 
    }
    new DbServiceService<Cell, Number>(this.http, environment.server_host+"/api/cells", this.cookieService).add(this.newCell).subscribe(data=>{
      alert("Cell Successfully Added")
      this.newCell = new Cell()
      this.setCalbDefault()
      this.updateStats()

    }, error=>{
      alert("something went wrong, addition failed \n"+error)
    })
  }

  setPackCondition(condition:PhysicalCondition){
    var index = this.newCell.physicalConditionsIds.indexOf(condition.id)
    if(index>-1){
      this.newCell.physicalConditionsIds.splice(index, 1)
    }else{
      this.newCell.physicalConditionsIds.push(condition.id)
    }
  }

  setCellType(type:ChildEntity){
    if(this.newCell.cellTypeId == type.id){
      this.newCell.cellTypeId = -1
    }else{
      this.newCell.cellTypeId = type.id
    }
  }

  setManufacturer(type:ChildEntity){
    if(this.newCell.manufacturerId == type.id){
      this.newCell.manufacturerId = -1
      this.newCell.cellModelId = -1
    }else{
      this.newCell.cellModelId = -1
      new DbServiceService<PhysicalCondition, Number>(this.http, environment.server_host+"/api/manufacturers/"+type.id+"/cell-models", this.cookieService).getAll().subscribe(data=>{
        this.cellModels = data
      })
      this.newCell.manufacturerId = type.id
    }
  }

  setChemistry(chemistry:ChildEntity){
    if(this.newCell.chemistryId == chemistry.id){
      this.newCell.chemistryId = -1
    }else{
      this.newCell.chemistryId = chemistry.id
    }
  }

  setEWaste(ewaste:ChildEntity){
    if(this.newCell.eWasteId == ewaste.id){
      this.newCell.eWasteId = -1
    }else{
      this.newCell.eWasteId = ewaste.id
    }
  }

  setModel(model:ChildEntity){
    if(this.newCell.cellModelId == model.id){
      this.newCell.cellModelId = -1
    }else{
      this.newCell.cellModelId = model.id
    }
  }

  validate(){
    var message = ""
    if(this.newCell.nunamId==null){
      message += "\nPlease Choose a valid nunam Id"
    }
    return message
  }

  switchMode(value:boolean){
    this.isSingleAdd = value
  }

  public mulltipleAddCells:any[] = []
  public endNunamId!:Number
  public startNunamId!:Number
  public mulltipleAddCellsStatus:any = {}
  public message!:String

  private validateIds(){
    
  }

  public generateNewCells(){
    if(this.startNunamId>this.endNunamId){
      alert("invalid start and end config")
      return 
    }
    if(+this.endNunamId - +this.startNunamId>50){
      alert("can add only upto 50 cells at a time")
      return 
    }
    var idsToCheck = []
    for(var v:number=this.startNunamId.valueOf();v<=this.endNunamId.valueOf();v++){
      idsToCheck.push(v)
    }
    new DbServiceService<any, any>(this.http, environment.server_host+"/api/cells/check-ids", this.cookieService).add(idsToCheck).subscribe(data=>{
      if(data.length>0){
        alert("some Ids in the range are already exists! please change the start and end config"+ data)
        return 
      }else{
        this.mulltipleAddCells = []
        this.mulltipleAddCellsStatus = {}
        for(var v:number=this.startNunamId.valueOf();v<=this.endNunamId.valueOf();v++){
          this.mulltipleAddCellsStatus[v]= false
          var c = new Cell()
          c.nunamId = v
          c.nominalVoltage = 3.2
          c.cellTypeId = 2
          c.chemistryId = 1
          c.manufacturerId = 1
          c.cellModelId = 1
          c.eWasteId = 1
          c.nominalCapacity = 72
          c.physicalConditionsIds = [7]
          this.mulltipleAddCells.push(c)
        }
      }
    }, error=>{
      alert("something went wrong, please try later")
    })
    // if(this.lastNunamId > this.startNunamId){
    //   alert("start nunam ID must be miore that recent Nunam Id")
    //   return 
    // }
    
  }

  public getCondition(id:number){
    for(var v=0;v<this.packConditions.length;v++){
      if(+this.packConditions[v].id == +id){
        return this.packConditions[v].name
      }
    }
    return ""
  }

  public onChangeMPC(nunamId:Number, pcId:any){
    pcId = pcId.value
    for(var v=0;v<this.mulltipleAddCells.length;v++){
      if(+this.mulltipleAddCells[v].nunamId == +nunamId){
        if(this.mulltipleAddCells[v].physicalConditionsIds.indexOf(pcId)==-1){
          this.mulltipleAddCells[v].physicalConditionsIds.push(pcId)
        }
      }
    }
    
  }
  public removeMPC(nunamId:Number, pcId:any){
    pcId = pcId.value
    for(var v=0;v<this.mulltipleAddCells.length;v++){
      if(+this.mulltipleAddCells[v].nunamId == +nunamId){
        if(this.mulltipleAddCells[v].physicalConditionsIds.indexOf(pcId)!=-1){
          this.mulltipleAddCells[v].physicalConditionsIds.splice(this.mulltipleAddCells[v].physicalConditionsIds.indexOf(pcId), 1);
        }
      }
    }
  }

  
  public addMultipleCells(){
    this.message = "Loading..."
    var cellService = new DbServiceService<any, any>(this.http, environment.server_host+"/api/cells", this.cookieService)
    var responses = []
    for(var v=0;v<this.mulltipleAddCells.length;v++){
      responses.push(cellService.add(this.mulltipleAddCells[v]))
    }
    forkJoin(responses).subscribe(data=>{
      data.forEach(cell=>{
        this.mulltipleAddCellsStatus[cell.nunamId] = true
      })
      this.message = ""
    })
  }
   
  public resetMul(){
    this.mulltipleAddCells = []
    this.mulltipleAddCellsStatus = {}
  }

  

}
