import { Component, OnInit } from '@angular/core';
import { Pack } from '../models/pack';
import { DbServiceService } from '../shared/services/dbservice.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { PackService } from './pack.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pack',
  templateUrl: './pack.component.html',
  styleUrls: ['./pack.component.css']
})
export class PackComponent implements OnInit {

  packService!:PackService
  public searchId!:string
  constructor(private http:HttpClient, private cookieService:CookieService, private router:Router) {
    this.packService = new PackService(http,environment.server_host+"/api/packs",cookieService)
  }

  displayedColumns: string[] = ["id", "numberOfCells", "time", "eWaste.name", "operation"]

  packs!:Pack[]
  ngOnInit(): void {
    this.packService.getWithFilterAndLimit("time", 10).subscribe(data=>{
      this.packs = data
      this.packs.forEach(element => {
        if(element.numberOfCells == null){
          element.numberOfCells = 0
        }
      });
    })
  }

}
